.footer-container {
  max-width: 100%;
  background-color: rgb(0, 0, 0);
  .footer-btns{
    display: flex;
    .btns-style{
      background-color: rgb(0, 123, 255);
      color: white;
      width: 50%;
      text-align: center;
      font-size: 2.1em;
      padding-top: 1.3em;
      padding-bottom: 1.3em;
      &:hover{
        background-color: yellow;
        color: black;
      }
    }
  }
  .columns-footer {
    display: flex;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 5em;
    gap: 12%;
  
    .pages-link,
    .footer-find-us,
    .footer-contact-info,
    .social-links-footer {
      flex-basis: 100%;
    }
  
    .pages-link,
    .footer-find-us,
    .footer-contact-info {
      margin-bottom: 2rem;
    }
  
    .pages-link {
      display: flex;
      flex-direction: column;
      gap: 0.2em;
  
      .page-link {
        color: white;
        font-size: 1.3em;
        &:hover {
          color: yellow;
        }
      }
    }
  
    .footer-find-us {
      color: white;
      h3 {
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: 0px;
      }
      p {
        font-size: 1rem;
      }
      a {
        color: white;
        &:hover {
          color: #ffc400;
        }
      }
    }
  
    .footer-contact-info {
      display: flex;
      flex-direction: column;
      h3 {
        color: white;
        font-size: 1.5em;
        font-weight: 700;
        margin-top: 0px;
      }
      a {
        color: white;
        &:hover {
          color: #ffc400;
        }
      }
    }
  
    .social-links-footer {
      display: flex;
  
      .social-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-color: yellow;
        margin: 2px;
        i {
          color: black;
        }
        &:hover {
          background-color: rgb(61, 61, 253);
          i {
            color: white;
          }
        }
      }
    }}
  .links-bottom {
    display: flex;
    align-items: center;
    gap: 5%;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
    .link-page{
      color: white;
      font-size: 1em;
      &:hover{
        color: #ffc400;
      }
    }  
  }
  .lower {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    gap: 50%;
    padding-top: 3rem;
    padding-bottom: 1rem;
    padding-left: 5%;
    padding-right: 5%;
    color: aliceblue;
    .p1 {
      font-size: 0.7rem;
      font-weight: 400;
    }
    .p2 {
      font-size: 1rem;
      font-weight: 600;
      a{
        color: #ffffff;
        &:hover{
          color: #ffc400;
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .footer-container {
  .footer-btns{
    
    text-align: center;
    .btns-style{
      width: 100%;
    }
  }
  .columns-footer {
    text-align: center;
    .social-links-footer{
      margin-left: -10px;
    }
  }
  .lower {
    text-align: center;
    
  }
}}
@media (max-width: 1024px) {
  .footer-container {
  .footer-btns{
    
    text-align: center;
    .btns-style{
      width: 100%;
    }
  }
  .columns-footer {
    text-align: center;
    .footer-find-us{
      display: none;
    }
  }
  .lower {
    text-align: center;
    gap: 40%;
    
  }
}}
@media (min-width:769px) and (max-width: 800px) {
  .footer-container {
  .footer-btns{
    
    text-align: center;
    .btns-style{
      width: 100%;
    }
  }
  .columns-footer {
    text-align: center;
    .social-links-footer {
      display: none;
    }
  }
  .lower {
    text-align: center;
    gap:20%;
    
  }
}}
@media (max-width: 768px) {
  .footer-container {
  .footer-btns{
    
    text-align: center;
    .btns-style{
      width: 100%;
    }
  }
  .columns-footer {
    text-align: center;
    .social-links-footer {
      display: none;
    }
  }
  .lower {
    text-align: center;
    gap:20%;
    
  }
}}
@media (max-width: 425px) {
  .footer-container {
  .footer-btns{
    flex-direction: column;
    gap: 5px;
    text-align: center;
    .btns-style{
      width: 100%;
    }
  }
  .columns-footer {
    flex-direction: column;
    text-align: center;
  }
  .lower {
    flex-direction: column;
    text-align: center;
    gap:20%;
    
  }
}}
@media (min-width:425px) and (max-width: 580px) {
  .footer-container {
  .footer-btns{
    flex-direction: column;
    gap: 5px;
    text-align: center;
    .btns-style{
      width: 100%;
    }
  }
  .columns-footer {
    flex-direction: column;
    text-align: center;
  }
  .lower {
    flex-direction: column;
    text-align: center;
    gap:20%;
    
  }
}}
@media (max-width: 375px) {
  .footer-container {
  .footer-btns{
    flex-direction: column;
    gap: 5px;
    text-align: center;
    .btns-style{
      width: 100%;
    }
  }
  .columns-footer {
    flex-direction: column;
    text-align: center;
  }
  .lower {
    flex-direction: column;
    text-align: center;
    gap:20%;
    
  }
}}
@media (max-width: 320px) {
  .footer-container {
  .footer-btns{
    flex-direction: column;
    gap: 5px;
    text-align: center;
    .btns-style{
      width: 100%;
    }
  }
  .columns-footer {
    flex-direction: column;
    text-align: center;
  }
  .links-bottom {
    flex-direction: column;
    gap:20px;
  }
  .lower {
    flex-direction: column;
    text-align: center;
    gap:20%;
    
  }
}}
@media (min-width:321px) and (max-width: 350px) {
  .footer-container {
  .footer-btns{
    flex-direction: column;
    gap: 5px;
    text-align: center;
    .btns-style{
      width: 100%;
    }
  }
  .columns-footer {
    flex-direction: column;
    text-align: center;
  }
  .links-bottom {
    flex-direction: column;
    gap:20px;
  }
  .lower {
    flex-direction: column;
    text-align: center;
    gap:20%;
    
  }
}}