.FAQ-container {
    background-color: #f7f7f7;
    color: #333;
  
    .FAQ-header {
        background-color: black;
        padding: 12% 5% 2%;
        font-weight: 700;
        color: white;
        font-size: 90px;
    }
  
    .FAQ-body {
        padding: 40px 60px;
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 40px;
  
      h2 {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 1rem;
      }
  
      ol {
        list-style-type: decimal;
        padding-left: 2rem;
  
        li {
          margin-bottom: 1rem;
  
          strong {
            display: block;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .FAQ-container {
        .FAQ-header {

        }
    }
  }

  @media screen and (max-width: 768px) {
    .FAQ-container {
        .FAQ-header {
            padding-top:95px;
            padding-left: 20px;
            font-weight: 700;
            font-size: 45px;
            text-align: center;
            padding-bottom: 45px;
        }
        .FAQ-body {
            padding: 20px 10px;  
        }
    }
  }

  @media screen and (max-width: 425px) {
    .FAQ-container {
        .FAQ-header {
            padding-top:95px;
            padding-left: 20px;
            font-weight: 700;
            font-size: 45px;
            text-align: center;
            padding-bottom: 45px;
        }
        .FAQ-body {
            padding: 20px 10px;  
        }
    }
  }

  @media screen and (max-width: 375px) {
    .FAQ-container {
        .FAQ-header {
            padding-top:95px;
            padding-left: 20px;
            font-weight: 700;
            font-size: 45px;
            text-align: center;
            padding-bottom: 45px;
        }
        .FAQ-body {
            padding: 20px 10px;  
        }
    }
  }

  @media screen and (max-width: 320px) {
    .FAQ-container {
        .FAQ-header {
            padding-top:95px;
            padding-left: 20px;
            font-weight: 700;
            font-size: 45px;
            text-align: center;
            padding-bottom: 45px;
        }
        .FAQ-body {
            padding: 20px 10px;  
        }
    }
  }