ul {
    list-style-type: none;
  }
  
body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans', sans-serif;
}
a{
  text-decoration: none !important ;
}