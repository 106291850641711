.impact-page{
    margin-bottom: 20px;
    .impact-header{
        background-color: black;
    padding: 12% 5% 2%;
    font-weight: 700;
    color: white;
    font-size: 90px;
    }
    
}
@media (min-width:621px) and (max-width:1300px){
    .impact-page {
      .newsletter-header {
        padding: 8% 5% 2%; /* Adjusted padding for smaller screens */
        font-size: 60px; /* Reduced font size */
      }
    }
  }
  
  @media (max-width:620px) {
    .impact-page {
      .impact-header {
        background-color: black;
        padding-top:95px;
        padding-left: 20px;
        font-weight: 700;
        font-size: 45px;
        text-align: center;
        padding-bottom: 45px;
      }
  }
}