.footer-container {
  max-width: 100%;
  background-color: rgb(0, 0, 0);
}
.footer-container .footer-btns {
  display: flex;
}
.footer-container .footer-btns .btns-style {
  background-color: rgb(0, 123, 255);
  color: white;
  width: 50%;
  text-align: center;
  font-size: 2.1em;
  padding-top: 1.3em;
  padding-bottom: 1.3em;
}
.footer-container .footer-btns .btns-style:hover {
  background-color: yellow;
  color: black;
}
.footer-container .columns-footer {
  display: flex;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 5em;
  gap: 12%;
}
.footer-container .columns-footer .pages-link,
.footer-container .columns-footer .footer-find-us,
.footer-container .columns-footer .footer-contact-info,
.footer-container .columns-footer .social-links-footer {
  flex-basis: 100%;
}
.footer-container .columns-footer .pages-link,
.footer-container .columns-footer .footer-find-us,
.footer-container .columns-footer .footer-contact-info {
  margin-bottom: 2rem;
}
.footer-container .columns-footer .pages-link {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}
.footer-container .columns-footer .pages-link .page-link {
  color: white;
  font-size: 1.3em;
}
.footer-container .columns-footer .pages-link .page-link:hover {
  color: yellow;
}
.footer-container .columns-footer .footer-find-us {
  color: white;
}
.footer-container .columns-footer .footer-find-us h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 0px;
}
.footer-container .columns-footer .footer-find-us p {
  font-size: 1rem;
}
.footer-container .columns-footer .footer-find-us a {
  color: white;
}
.footer-container .columns-footer .footer-find-us a:hover {
  color: #ffc400;
}
.footer-container .columns-footer .footer-contact-info {
  display: flex;
  flex-direction: column;
}
.footer-container .columns-footer .footer-contact-info h3 {
  color: white;
  font-size: 1.5em;
  font-weight: 700;
  margin-top: 0px;
}
.footer-container .columns-footer .footer-contact-info a {
  color: white;
}
.footer-container .columns-footer .footer-contact-info a:hover {
  color: #ffc400;
}
.footer-container .columns-footer .social-links-footer {
  display: flex;
}
.footer-container .columns-footer .social-links-footer .social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: yellow;
  margin: 2px;
}
.footer-container .columns-footer .social-links-footer .social-icon i {
  color: black;
}
.footer-container .columns-footer .social-links-footer .social-icon:hover {
  background-color: rgb(61, 61, 253);
}
.footer-container .columns-footer .social-links-footer .social-icon:hover i {
  color: white;
}
.footer-container .links-bottom {
  display: flex;
  align-items: center;
  gap: 5%;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}
.footer-container .links-bottom .link-page {
  color: white;
  font-size: 1em;
}
.footer-container .links-bottom .link-page:hover {
  color: #ffc400;
}
.footer-container .lower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50%;
  padding-top: 3rem;
  padding-bottom: 1rem;
  padding-left: 5%;
  padding-right: 5%;
  color: aliceblue;
}
.footer-container .lower .p1 {
  font-size: 0.7rem;
  font-weight: 400;
}
.footer-container .lower .p2 {
  font-size: 1rem;
  font-weight: 600;
}
.footer-container .lower .p2 a {
  color: #ffffff;
}
.footer-container .lower .p2 a:hover {
  color: #ffc400;
}

@media (max-width: 1440px) {
  .footer-container .footer-btns {
    text-align: center;
  }
  .footer-container .footer-btns .btns-style {
    width: 100%;
  }
  .footer-container .columns-footer {
    text-align: center;
  }
  .footer-container .columns-footer .social-links-footer {
    margin-left: -10px;
  }
  .footer-container .lower {
    text-align: center;
  }
}
@media (max-width: 1024px) {
  .footer-container .footer-btns {
    text-align: center;
  }
  .footer-container .footer-btns .btns-style {
    width: 100%;
  }
  .footer-container .columns-footer {
    text-align: center;
  }
  .footer-container .columns-footer .footer-find-us {
    display: none;
  }
  .footer-container .lower {
    text-align: center;
    gap: 40%;
  }
}
@media (min-width: 769px) and (max-width: 800px) {
  .footer-container .footer-btns {
    text-align: center;
  }
  .footer-container .footer-btns .btns-style {
    width: 100%;
  }
  .footer-container .columns-footer {
    text-align: center;
  }
  .footer-container .columns-footer .social-links-footer {
    display: none;
  }
  .footer-container .lower {
    text-align: center;
    gap: 20%;
  }
}
@media (max-width: 768px) {
  .footer-container .footer-btns {
    text-align: center;
  }
  .footer-container .footer-btns .btns-style {
    width: 100%;
  }
  .footer-container .columns-footer {
    text-align: center;
  }
  .footer-container .columns-footer .social-links-footer {
    display: none;
  }
  .footer-container .lower {
    text-align: center;
    gap: 20%;
  }
}
@media (max-width: 425px) {
  .footer-container .footer-btns {
    flex-direction: column;
    gap: 5px;
    text-align: center;
  }
  .footer-container .footer-btns .btns-style {
    width: 100%;
  }
  .footer-container .columns-footer {
    flex-direction: column;
    text-align: center;
  }
  .footer-container .lower {
    flex-direction: column;
    text-align: center;
    gap: 20%;
  }
}
@media (min-width: 425px) and (max-width: 580px) {
  .footer-container .footer-btns {
    flex-direction: column;
    gap: 5px;
    text-align: center;
  }
  .footer-container .footer-btns .btns-style {
    width: 100%;
  }
  .footer-container .columns-footer {
    flex-direction: column;
    text-align: center;
  }
  .footer-container .lower {
    flex-direction: column;
    text-align: center;
    gap: 20%;
  }
}
@media (max-width: 375px) {
  .footer-container .footer-btns {
    flex-direction: column;
    gap: 5px;
    text-align: center;
  }
  .footer-container .footer-btns .btns-style {
    width: 100%;
  }
  .footer-container .columns-footer {
    flex-direction: column;
    text-align: center;
  }
  .footer-container .lower {
    flex-direction: column;
    text-align: center;
    gap: 20%;
  }
}
@media (max-width: 320px) {
  .footer-container .footer-btns {
    flex-direction: column;
    gap: 5px;
    text-align: center;
  }
  .footer-container .footer-btns .btns-style {
    width: 100%;
  }
  .footer-container .columns-footer {
    flex-direction: column;
    text-align: center;
  }
  .footer-container .links-bottom {
    flex-direction: column;
    gap: 20px;
  }
  .footer-container .lower {
    flex-direction: column;
    text-align: center;
    gap: 20%;
  }
}
@media (min-width: 321px) and (max-width: 350px) {
  .footer-container .footer-btns {
    flex-direction: column;
    gap: 5px;
    text-align: center;
  }
  .footer-container .footer-btns .btns-style {
    width: 100%;
  }
  .footer-container .columns-footer {
    flex-direction: column;
    text-align: center;
  }
  .footer-container .links-bottom {
    flex-direction: column;
    gap: 20px;
  }
  .footer-container .lower {
    flex-direction: column;
    text-align: center;
    gap: 20%;
  }
}/*# sourceMappingURL=footer.css.map */