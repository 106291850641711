.report-page {
  margin-bottom: 50px;
      
    .report-header {
      background-color: black;
      padding: 12% 5% 2%;
      font-weight: 700;
      color: white;
      font-size: 90px;
    }
    .report-content{
      background-color: #ebebeb;
          padding-left: 40px;
          padding-top: 10px;
          padding-bottom: -10px;
    .report-drive{
        width:100%; 
        height:300px; 
        overflow:hidden;
     }
     .documents-subheading{
        font-size: 52px;
        font-weight: 700;
        line-height: 47px;
        font-style: normal;
        margin-bottom: 10px;
      }
     .line {
        width: 95%; 
        height: 0.8px;
        background-color: rgb(0, 0, 0);
      }  
      .certificate {
        margin-top: 50px;
        margin-bottom: 50px;
        width: 450px;
      }
  }}
  @media (max-width:1024px) {
    .report-page {
      .report-header {
    
      }
      .report-content{
        
      .report-drive{
          width:100%; 
          height:300px; 
          overflow:hidden;
       }
       .documents-subheading{
      
        }
      
        .certificate {
          
        }
    }
    }
  }
  @media (max-width:769px) {
    .report-page {
      .report-header {
       
        padding-top:95px;
        padding-left: 20px;
        font-weight: 700;
        font-size: 45px;
        text-align: center;
        padding-bottom: 45px;
      }
      .report-content{
        background-color: #ebebeb;
            padding-left: 40px;
            padding-top: 10px;
            padding-bottom: -10px;
      .report-drive{
          width:100%; 
          height:800px; 
          overflow:hidden;
       }
       .documents-subheading{
          font-size: 2.8rem;
          font-weight: 700;
          line-height: 47px;
          font-style: normal;
          margin-bottom: 10px;
        }
       .line {
          width: 95%; 
          height: 0.8px;
          background-color: rgb(0, 0, 0);
        }  
        .certificate {
          margin-top: 50px;
          margin-bottom: 50px;
          width: 250px;
        }
    }
    }
  }
  @media (max-width:426px) {
    .report-page {
      .report-header {
       
        padding-top:95px;
        padding-left: 20px;
        font-weight: 700;
        font-size: 45px;
        text-align: center;
        padding-bottom: 45px;
      }
      .report-content{
        background-color: #ebebeb;
            padding-left: 40px;
            padding-top: 10px;
            padding-bottom: -10px;
      .report-drive{
          width:100%; 
          height:800px; 
          overflow:hidden;
       }
       .documents-subheading{
          font-size: 2.8rem;
          font-weight: 700;
          line-height: 47px;
          font-style: normal;
          margin-bottom: 10px;
        }
       .line {
          width: 95%; 
          height: 0.8px;
          background-color: rgb(0, 0, 0);
        }  
        .certificate {
          margin-top: 50px;
          margin-bottom: 50px;
          width: 330px;
        }
    }
    }
  }
  @media (max-width:376px) {
    .report-page {
      .report-header {
        background-color: black;
        padding-top:95px;
        padding-left: 20px;
        font-weight: 700;
        font-size: 45px;
        text-align: center;
        padding-bottom: 45px;
      }
      .report-content{
        background-color: #ebebeb;
            padding-left: 40px;
            padding-top: 10px;
            padding-bottom: -10px;
      .report-drive{
          width:100%; 
          height:800px; 
          overflow:hidden;
       }
       .documents-subheading{
          font-size: 2.5rem;
          font-weight: 700;
          line-height: 47px;
          font-style: normal;
          margin-bottom: 10px;
        }
       .line {
          width: 95%; 
          height: 0.8px;
          background-color: rgb(0, 0, 0);
        }  
        .certificate {
          margin-top: 50px;
          margin-bottom: 50px;
          width: 290px;
        }
    }
    }
  }
  @media (max-width:320px) {
    .report-page {
      .report-header {
        background-color: black;
        padding-top:95px;
        padding-left: 20px;
        font-weight: 700;
        font-size: 45px;
        text-align: center;
        padding-bottom: 45px;
      }
      .report-content{
        background-color: #ebebeb;
            padding-left: 40px;
            padding-top: 10px;
            padding-bottom: -10px;
      .report-drive{
          width:100%; 
          height:800px; 
          overflow:hidden;
       }
       .documents-subheading{
          font-size: 2rem;
          font-weight: 700;
          line-height: 47px;
          font-style: normal;
          margin-bottom: 10px;
        }
       .line {
          width: 95%; 
          height: 0.8px;
          background-color: rgb(0, 0, 0);
        }  
        .certificate {
          margin-top: 50px;
          margin-bottom: 50px;
          width: 250px;
        }
    }
    }
  }
  