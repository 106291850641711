.successstory-page {
  margin-bottom: 20px;
}
.successstory-page .successstory-header {
  background-color: black;
  padding: 12% 5% 2%;
  font-weight: 700;
  color: white;
  font-size: 90px;
}
.successstory-page .testimonials-section {
  background-color: rgb(224, 224, 224);
}
.successstory-page .testimonials-section h2 {
  padding-top: 50px;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 60px;
  margin-bottom: 100px;
}
.successstory-page .testimonials-section .testimonials-container {
  padding: 0 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
}
.successstory-page .testimonials-section .testimonials-container .testimonial {
  background-color: white;
  padding: 20px;
  border: 1px solid black;
  border-radius: 20px;
  text-align: center;
  position: relative;
  margin-bottom: 100px;
}
.successstory-page .testimonials-section .testimonials-container .testimonial .testimonial-image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 3px solid black;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
}
.successstory-page .testimonials-section .testimonials-container .testimonial .testimonial-content {
  padding-top: 70px;
}
.successstory-page .testimonials-section .testimonials-container .testimonial .testimonial-content .testimonial-text {
  font: 1em Founders Grotesk Cond;
  font-size: 17px;
  text-align: justify;
  margin-bottom: 10px;
  font-weight: 300;
  max-height: 180px;
  overflow-y: auto;
}
.successstory-page .testimonials-section .testimonials-container .testimonial .testimonial-content .testimonial-author {
  font-size: 19px;
  font-weight: bold;
}

@media (max-width: 1024px) {
  .successstory-page .successstory-header {
    font-size: 4rem;
  }
}
@media (max-width: 768px) {
  .successstory-page .successstory-header {
    font-size: 3rem;
  }
}
@media (max-width: 426px) {
  .successstory-page .successstory-header {
    padding-top: 95px;
    padding-left: 20px;
    font-size: 2rem;
    text-align: center;
    padding-bottom: 45px;
  }
  .successstory-page .testimonials-section h2 {
    font-size: 2rem;
  }
  .successstory-page .testimonials-section .testimonials-container {
    padding: 0 10px;
  }
}
@media (max-width: 375px) {
  .successstory-page .successstory-header {
    font-size: 2rem;
    text-align: center;
    padding-bottom: 45px;
  }
  .successstory-page .testimonials-section h2 {
    font-size: 2rem;
  }
  .successstory-page .testimonials-section .testimonials-container {
    padding: 0 10px;
  }
}
@media (max-width: 321px) {
  .successstory-page .successstory-header {
    font-size: 2rem;
    text-align: center;
    padding-bottom: 45px;
  }
  .successstory-page .testimonials-section h2 {
    font-size: 2rem;
  }
  .successstory-page .testimonials-section .testimonials-container {
    padding: 0 10px;
  }
}/*# sourceMappingURL=successstory.css.map */