.navbar-container {
  position: fixed;
  max-width: 100%;
  max-height: 50px;
  top: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: transparent;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  align-items: center;
  z-index: 1000;
  border-bottom: #ffffff solid 1px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
.navbar-container:hover {
  background-color: rgb(255, 255, 255);
  border-bottom: #000000 solid 1px;
}
.navbar-container:hover .nav-item {
  border-right: rgb(0, 0, 0) solid 1px;
}
.navbar-container:hover .nav-item .nav-link {
  color: rgb(0, 0, 0) !important; /* Change the text color to black on hover */
}
.navbar-container.scrolled {
  background-color: #ffffff;
  border-bottom: #000000 solid 1px;
  top: 0;
}
.navbar-container.scrolled .nav-item {
  border-right-color: black; /* Change the border color to black when scrolled */
}
.navbar-container.scrolled .nav-item .nav-link {
  color: #000000 !important; /* Change the text color to black when scrolled */
}
.navbar-container .navbar-left {
  display: flex;
  justify-content: flex-start;
}
.navbar-container .navbar-left .navbar-brand img {
  padding-left: 1%;
  width: 155px;
}
.navbar-container .navbar-mid {
  display: flex;
  height: 50px;
  margin-left: 13%;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  border: #ffffff solid 1px;
}
.navbar-container .navbar-mid:hover {
  border-color: #000000;
}
.navbar-container .navbar-mid:hover .nav-item {
  border-right-color: black;
}
.navbar-container .navbar-mid:hover .nav-item .nav-link {
  color: rgb(0, 0, 0) !important;
}
.navbar-container .navbar-mid.scrolled {
  border-color: #000000;
}
.navbar-container .navbar-mid .menu-button {
  display: none;
  color: #12d9a7;
  z-index: 1100;
  cursor: pointer;
}
.navbar-container .navbar-mid .menu-button.scrolled {
  color: #07fd03;
}
.navbar-container .navbar-mid .menu-button.hover {
  color: #000000;
}
.navbar-container .navbar-list {
  margin-left: -40px;
  display: flex;
  align-items: center;
  z-index: 1050;
}
.navbar-container .navbar-list .nav-item {
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  line-height: 15px;
  font-weight: 500;
  background-color: transparent;
  border: none;
  border-right: rgb(255, 255, 255) solid 1px;
  position: relative;
}
.navbar-container .navbar-list .nav-item.scrolled {
  border-right: rgb(0, 0, 0) solid 1px;
}
.navbar-container .navbar-list .nav-item::before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  background-color: rgb(255, 94, 0);
  transition: height 1s ease, width 1s ease, background-color 0.3s ease;
}
.navbar-container .navbar-list .nav-item .nav-link {
  padding-top: 10px;
  padding-bottom: 5px;
  align-self: center;
  justify-content: center;
  color: #f40808;
  transition: color 1s ease;
}
.navbar-container .navbar-list .nav-item .nav-link.scrolled {
  color: #f70303;
}
.navbar-container .navbar-list .nav-item:hover {
  border-right: rgb(0, 0, 0) solid 1px;
}
.navbar-container .navbar-list .nav-item:hover .nav-link {
  color: rgb(255, 255, 255);
}
.navbar-container .navbar-list .nav-item:hover::before {
  height: 3px;
  width: 100%;
  background-color: hsl(0, 0%, 0%);
  border-right: rgb(0, 0, 0) solid 1px;
}
.navbar-container .nav-item {
  color: #000000;
  border-right: #000000 solid 1px;
  background-color: yellow;
}
.navbar-container .nav-item .nav-link {
  color: rgb(254, 254, 254) !important;
}
.navbar-container .nav-item:hover {
  border-right: #000000 solid 1px;
  background-color: yellow;
}
.navbar-container .nav-item:hover .nav-link {
  color: rgb(0, 0, 0) !important;
}
.navbar-container .navbar-right {
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navbar-container .navbar-right .nav-right-button {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}
.navbar-container .navbar-right .nav-right-button li {
  margin-right: 30px;
}
.navbar-container .navbar-right .nav-right-button li .Donate-btn-nav {
  font-weight: 700;
  font-size: 1rem;
  position: relative;
  background: transparent;
  border: #000000 solid 1px;
  background-color: yellow;
  padding: 29%;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}
.navbar-container .navbar-right .nav-right-button li .Donate-btn-nav::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  background-color: blue;
  transition: height 0.9s ease;
}
.navbar-container .navbar-right .nav-right-button li .Donate-btn-nav:hover {
  background-color: blue;
  color: #ffffff;
}
.navbar-container .navbar-right .nav-right-button li .Donate-btn-nav:hover::before {
  height: 100%;
  z-index: -1000;
}
.navbar-container .dropdown {
  position: relative;
  display: inline-block;
}
.navbar-container .dropdown:hover .dropdown-content {
  display: block;
}
.navbar-container .dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 140px;
  min-height: 5px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1060;
}
.navbar-container .dropdown-content .nav-link {
  color: #000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 0.9rem;
  font-weight: lighter;
}
.navbar-container .dropdown-content .nav-link:hover {
  background-color: yellow;
}

.navbar-brand img.default-logo,
.navbar-brand img.scrolled-logo {
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.navbar-container:hover .navbar-brand img.scrolled-logo {
  opacity: 0;
}

.navbar-container:hover .navbar-brand img.default-logo {
  opacity: 0;
}

.navbar-container:hover .navbar-brand img.scrolled-logo {
  opacity: 1 !important;
}

.navbar-container:hover .navbar-brand img.default-logo {
  opacity: 0 !important;
}

@media (max-width: 1440px) {
  .navbar-container {
    position: fixed;
    max-width: 100%;
    max-height: 50px;
    top: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background: transparent;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    align-items: center;
    z-index: 1000;
    border-bottom: #ffffff solid 1px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  .navbar-container:hover {
    background-color: rgb(255, 255, 255);
    border-bottom: #000000 solid 1px;
  }
  .navbar-container:hover .nav-item {
    border-right: rgb(0, 0, 0) solid 1px;
  }
  .navbar-container:hover .nav-item .nav-link {
    color: rgb(0, 0, 0) !important; /* Change the text color to black on hover */
  }
  .navbar-container.scrolled {
    background-color: #ffffff;
    border-bottom: #000000 solid 1px;
    top: 0;
  }
  .navbar-container.scrolled .nav-item {
    border-right-color: black; /* Change the border color to black when scrolled */
  }
  .navbar-container.scrolled .nav-item .nav-link {
    color: #000000 !important; /* Change the text color to black when scrolled */
  }
  .navbar-container .navbar-left {
    display: flex;
    justify-content: flex-start;
  }
  .navbar-container .navbar-left .navbar-brand img {
    padding-left: 1%;
    width: 155px;
  }
  .navbar-container .navbar-mid {
    display: flex;
    height: 50px;
    margin-left: 18%;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    border: #ffffff solid 1px;
  }
  .navbar-container .navbar-mid:hover {
    border-color: #000000;
  }
  .navbar-container .navbar-mid:hover .nav-item {
    border-right-color: black;
  }
  .navbar-container .navbar-mid:hover .nav-item .nav-link {
    color: black !important;
  }
  .navbar-container .navbar-mid.scrolled {
    border-color: #000000;
  }
  .navbar-container .navbar-mid .menu-button {
    display: none;
    color: #0fa4e9;
    z-index: 1100;
  }
  .navbar-container .navbar-mid .menu-button.scrolled {
    color: #ea0909;
  }
  .navbar-container .navbar-mid .menu-button.hover {
    color: #000000;
  }
  .navbar-container .navbar-list {
    margin-left: -40px;
    display: flex;
    align-items: center;
    z-index: 1050;
  }
  .navbar-container .navbar-list .nav-item {
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    line-height: 15px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    border-right: rgb(255, 255, 255) solid 1px;
    position: relative;
  }
  .navbar-container .navbar-list .nav-item.scrolled {
    border-right: rgb(0, 0, 0) solid 1px;
  }
  .navbar-container .navbar-list .nav-item::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    background-color: yellow;
    transition: height 1s ease, width 1s ease, background-color 0.3s ease;
  }
  .navbar-container .navbar-list .nav-item .nav-link {
    padding-top: 10px;
    padding-bottom: 5px;
    align-self: center;
    justify-content: center;
    color: #ffffff;
    transition: color 1s ease;
  }
  .navbar-container .navbar-list .nav-item .nav-link.scrolled {
    color: #000000;
  }
  .navbar-container .navbar-list .nav-item:hover {
    border-right: rgb(0, 0, 0) solid 1px;
  }
  .navbar-container .navbar-list .nav-item:hover .nav-link {
    color: rgb(255, 255, 255);
  }
  .navbar-container .navbar-list .nav-item:hover::before {
    height: 3px;
    width: 100%;
    background-color: hsl(0, 0%, 0%);
    border-right: rgb(0, 0, 0) solid 1px;
  }
}
@media (max-width: 1024px) {
  .navbar-container {
    position: fixed;
    max-width: 100%;
    max-height: 50px;
    top: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background: transparent;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    align-items: center;
    z-index: 1000;
    border-bottom: #ffffff solid 1px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  .navbar-container:hover {
    background-color: rgb(255, 255, 255);
    border-bottom: #000000 solid 1px;
  }
  .navbar-container:hover .nav-item {
    border-right: rgb(0, 0, 0) solid 1px;
  }
  .navbar-container:hover .nav-item .nav-link {
    color: rgb(0, 0, 0) !important; /* Change the text color to black on hover */
  }
  .navbar-container.scrolled {
    background-color: #ffffff;
    border-bottom: #000000 solid 1px;
    top: 0;
  }
  .navbar-container.scrolled .nav-item {
    border-right-color: black; /* Change the border color to black when scrolled */
  }
  .navbar-container.scrolled .nav-item .nav-link {
    color: #000000 !important; /* Change the text color to black when scrolled */
  }
  .navbar-container .navbar-left {
    display: flex;
    justify-content: flex-start;
  }
  .navbar-container .navbar-left .navbar-brand img {
    padding-left: 1%;
    width: 155px;
  }
  .navbar-container .navbar-mid {
    display: flex;
    height: 50px;
    margin-left: 18%;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    border: #ffffff solid 1px;
  }
  .navbar-container .navbar-mid:hover {
    border-color: #000000;
  }
  .navbar-container .navbar-mid:hover .nav-item {
    border-right-color: black;
  }
  .navbar-container .navbar-mid:hover .nav-item .nav-link {
    color: black !important;
  }
  .navbar-container .navbar-mid.scrolled {
    border-color: #000000;
  }
  .navbar-container .navbar-mid .menu-button {
    display: none;
    color: #ffffff;
    z-index: 1100;
  }
  .navbar-container .navbar-mid .menu-button.scrolled {
    color: #000000;
  }
  .navbar-container .navbar-mid .menu-button.hover {
    color: #000000;
  }
  .navbar-container .navbar-list {
    margin-left: -40px;
    display: flex;
    align-items: center;
    z-index: 1050;
  }
  .navbar-container .navbar-list .nav-item {
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    line-height: 15px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    border-right: rgb(255, 255, 255) solid 1px;
    position: relative;
  }
  .navbar-container .navbar-list .nav-item.scrolled {
    border-right: rgb(0, 0, 0) solid 1px;
  }
  .navbar-container .navbar-list .nav-item::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    background-color: yellow;
    transition: height 1s ease, width 1s ease, background-color 0.3s ease;
  }
  .navbar-container .navbar-list .nav-item .nav-link {
    padding-top: 10px;
    padding-bottom: 5px;
    align-self: center;
    justify-content: center;
    color: #ffffff;
    transition: color 1s ease;
  }
  .navbar-container .navbar-list .nav-item .nav-link.scrolled {
    color: #000000;
  }
  .navbar-container .navbar-list .nav-item:hover {
    border-right: rgb(0, 0, 0) solid 1px;
  }
  .navbar-container .navbar-list .nav-item:hover .nav-link {
    color: rgb(255, 255, 255);
  }
  .navbar-container .navbar-list .nav-item:hover::before {
    height: 3px;
    width: 100%;
    background-color: hsl(0, 0%, 0%);
    border-right: rgb(0, 0, 0) solid 1px;
  }
}
@media (min-width: 769px) and (max-width: 886px) {
  .navbar-container {
    max-width: 100%;
    -webkit-backdrop-filter: blur(0px);
            backdrop-filter: blur(0px);
    top: 17.2px;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .navbar-container.scrolled {
    top: 0;
  }
  .navbar-container .navbar-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .navbar-container .navbar-left .navbar-brand img {
    margin-top: 0.2rem;
    width: 130px;
  }
  .navbar-container .navbar-mid {
    display: flex;
    height: 60px;
    margin-left: auto;
    flex-grow: 0;
    justify-content: space-between;
    border: none;
    padding: 0;
  }
  .navbar-container .navbar-mid .menu-button {
    background: transparent;
    border: none;
    font-weight: 600;
    margin-left: 10px;
    padding: 13px 13px;
    display: block;
    cursor: pointer;
    font-size: 16px;
    color: #ffffff;
    z-index: 1100;
  }
  .navbar-container .navbar-mid .menu-button.scrolled {
    color: #000000;
  }
  .navbar-container .navbar-mid .menu-button:hover {
    color: #000000;
  }
  .navbar-container .navbar-mid .navbar-list {
    display: grid;
    flex-direction: column;
    position: absolute;
    top: 70%;
    border: 1px solid black;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9960784314);
    padding: auto;
    visibility: hidden;
    z-index: 1050;
    opacity: 100%;
    transition: visibility 0s, opacity 0.5s linear;
    width: 100%;
  }
  .navbar-container .navbar-mid .navbar-list.mobile-menu-open {
    visibility: visible;
    opacity: 100%;
  }
  .navbar-container .navbar-mid .navbar-list .nav-item {
    width: 100%;
    border: 1px solid black;
    height: 50px;
  }
  .navbar-container .navbar-mid .navbar-list .nav-item .nav-link {
    padding-top: 3px;
    padding-bottom: 2px;
    display: block;
    width: 100%;
    color: #000;
  }
  .navbar-container .navbar-mid .navbar-list .dropdown-content {
    position: static;
    box-shadow: none;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    padding: 0;
    font-size: 20px;
    text-align: center;
    z-index: 1060;
  }
  .navbar-container .navbar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .navbar-container .navbar-right .nav-right-button {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .navbar-container .navbar-right .nav-right-button li {
    margin-right: auto;
  }
  .navbar-container .navbar-right .nav-right-button li .Donate-btn-nav {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    background: transparent;
    border: #000000 solid 1px;
    background-color: yellow;
    padding: 12px;
  }
}
.navbar-container:hover .menu-button {
  color: #000 !important;
}

@media (max-width: 769px) {
  .navbar-container {
    max-width: 100%;
    -webkit-backdrop-filter: blur(0px);
            backdrop-filter: blur(0px);
    top: 17.2px;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .navbar-container.scrolled {
    top: 0;
  }
  .navbar-container .navbar-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .navbar-container .navbar-left .navbar-brand img {
    margin-top: 0.2rem;
    width: 130px;
  }
  .navbar-container .navbar-mid {
    display: flex;
    height: 60px;
    margin-left: auto;
    flex-grow: 0;
    justify-content: space-between;
    border: none;
    padding: 0;
  }
  .navbar-container .navbar-mid .menu-button {
    background: transparent;
    border: none;
    font-weight: 600;
    margin-left: 10px;
    padding: 13px 13px;
    display: block;
    cursor: pointer;
    font-size: 16px;
    color: #ffffff;
    z-index: 1100;
  }
  .navbar-container .navbar-mid .menu-button.scrolled {
    color: #000000;
  }
  .navbar-container .navbar-mid .menu-button:hover {
    color: #000000;
  }
  .navbar-container .navbar-mid .navbar-list {
    display: grid;
    flex-direction: column;
    position: absolute;
    top: 70%;
    border: 1px solid black;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9960784314);
    padding: auto;
    visibility: hidden;
    z-index: 1050;
    opacity: 100%;
    transition: visibility 0s, opacity 0.5s linear;
    width: 100%;
  }
  .navbar-container .navbar-mid .navbar-list.mobile-menu-open {
    visibility: visible;
    opacity: 100%;
  }
  .navbar-container .navbar-mid .navbar-list .nav-item {
    width: 100%;
    border: 1px solid black;
    height: 50px;
  }
  .navbar-container .navbar-mid .navbar-list .nav-item .nav-link {
    padding-top: 3px;
    padding-bottom: 2px;
    display: block;
    width: 100%;
    color: #000;
  }
  .navbar-container .navbar-mid .navbar-list .dropdown-content {
    position: static;
    box-shadow: none;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    padding: 0;
    font-size: 20px;
    text-align: center;
    z-index: 1060;
  }
  .navbar-container .navbar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .navbar-container .navbar-right .nav-right-button {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .navbar-container .navbar-right .nav-right-button li {
    margin-right: auto;
  }
  .navbar-container .navbar-right .nav-right-button li .Donate-btn-nav {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    background: transparent;
    border: #000000 solid 1px;
    background-color: yellow;
    padding: 12px;
  }
}
.navbar-container:hover .menu-button {
  color: #000 !important;
}

@media (max-width: 426px) {
  .navbar-container {
    max-width: 100%;
    -webkit-backdrop-filter: blur(0px);
            backdrop-filter: blur(0px);
    top: 17.2px;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .navbar-container.scrolled {
    top: 0;
  }
  .navbar-container .navbar-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .navbar-container .navbar-left .navbar-brand img {
    margin-top: 0.2rem;
    width: 130px;
  }
  .navbar-container .navbar-mid {
    display: flex;
    height: 60px;
    margin-left: auto;
    flex-grow: 0;
    justify-content: space-between;
    border: none;
    padding: 0;
  }
  .navbar-container .navbar-mid .menu-button {
    background: transparent;
    border: none;
    font-weight: 600;
    margin-left: 10px;
    padding: 13px 13px;
    display: block;
    cursor: pointer;
    font-size: 16px;
    color: #ffffff;
    z-index: 1100;
  }
  .navbar-container .navbar-mid .menu-button.scrolled {
    color: #000000;
  }
  .navbar-container .navbar-mid .menu-button:hover {
    color: #000000;
  }
  .navbar-container .navbar-mid .navbar-list {
    display: grid;
    flex-direction: column;
    position: absolute;
    top: 70%;
    border: 1px solid black;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9960784314);
    padding: auto;
    visibility: hidden;
    z-index: 1050;
    opacity: 100%;
    transition: visibility 0s, opacity 0.5s linear;
    width: 100%;
  }
  .navbar-container .navbar-mid .navbar-list.mobile-menu-open {
    visibility: visible;
    opacity: 100%;
  }
  .navbar-container .navbar-mid .navbar-list .nav-item {
    width: 100%;
    border: 1px solid black;
    height: 50px;
  }
  .navbar-container .navbar-mid .navbar-list .nav-item .nav-link {
    padding-top: 3px;
    padding-bottom: 2px;
    display: block;
    width: 100%;
    color: #000;
  }
  .navbar-container .navbar-mid .navbar-list .dropdown-content {
    position: static;
    box-shadow: none;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    padding: 0;
    font-size: 20px;
    text-align: center;
    z-index: 1060;
  }
  .navbar-container .navbar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .navbar-container .navbar-right .nav-right-button {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .navbar-container .navbar-right .nav-right-button li {
    margin-right: -10px;
  }
  .navbar-container .navbar-right .nav-right-button li .Donate-btn-nav {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    background: transparent;
    border: #000000 solid 1px;
    background-color: yellow;
    padding: 12px;
  }
}
.navbar-container:hover .menu-button {
  color: #000 !important;
}

@media (max-width: 376px) {
  .navbar-container {
    max-width: 100%;
    -webkit-backdrop-filter: blur(0px);
            backdrop-filter: blur(0px);
    top: 17.2px;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .navbar-container.scrolled {
    top: 0;
  }
  .navbar-container .navbar-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .navbar-container .navbar-left .navbar-brand img {
    margin-top: 0.2rem;
    width: 130px;
  }
  .navbar-container .navbar-mid {
    display: flex;
    height: 60px;
    margin-left: auto;
    flex-grow: 0;
    justify-content: space-between;
    border: none;
    padding: 0;
  }
  .navbar-container .navbar-mid .menu-button {
    background: transparent;
    border: none;
    font-weight: 600;
    margin-left: 10px;
    padding: 13px 13px;
    display: block;
    z-index: 1100;
    cursor: pointer;
    font-size: 16px;
    color: #ffffff;
  }
  .navbar-container .navbar-mid .menu-button.scrolled {
    color: #000000;
  }
  .navbar-container .navbar-mid .menu-button:hover {
    color: #000000;
  }
  .navbar-container .navbar-mid .navbar-list {
    display: grid;
    flex-direction: column;
    position: absolute;
    top: 70%;
    border: 1px solid black;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9960784314);
    padding: auto;
    visibility: hidden;
    z-index: 1050;
    opacity: 100%;
    transition: visibility 0s, opacity 0.5s linear;
    width: 100%;
  }
  .navbar-container .navbar-mid .navbar-list.mobile-menu-open {
    visibility: visible;
    opacity: 100%;
  }
  .navbar-container .navbar-mid .navbar-list .nav-item {
    width: 100%;
    border: 1px solid black;
    height: 50px;
  }
  .navbar-container .navbar-mid .navbar-list .nav-item .nav-link {
    padding-top: 3px;
    padding-bottom: 2px;
    display: block;
    width: 100%;
    color: #000;
  }
  .navbar-container .navbar-mid .navbar-list .dropdown-content {
    position: static;
    box-shadow: none;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    padding: 0;
    font-size: 20px;
    text-align: center;
    z-index: 1060;
  }
  .navbar-container .navbar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .navbar-container .navbar-right .nav-right-button {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .navbar-container .navbar-right .nav-right-button li {
    margin-right: -10px;
  }
  .navbar-container .navbar-right .nav-right-button li .Donate-btn-nav {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    background: transparent;
    border: #000000 solid 1px;
    background-color: yellow;
    padding: 12px;
  }
}
.navbar-container:hover .menu-button {
  color: #000 !important;
}

@media (max-width: 321px) {
  .navbar-container {
    max-width: 100%;
    height: 42px;
    -webkit-backdrop-filter: blur(0px);
            backdrop-filter: blur(0px);
    top: 17.2px;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .navbar-container.scrolled {
    top: 0;
  }
  .navbar-container .navbar-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .navbar-container .navbar-left .navbar-brand img {
    margin-top: 0.05rem;
    width: 120px;
  }
  .navbar-container .navbar-mid {
    margin-left: auto;
    border: none;
    padding: 0;
  }
  .navbar-container .navbar-mid .menu-button {
    background: transparent;
    border: none;
    z-index: 1100;
    padding: 13px 13px;
    display: block;
    cursor: pointer;
    font-size: 0.9rem;
    color: #ffffff;
  }
  .navbar-container .navbar-mid .menu-button.scrolled {
    color: #000000;
  }
  .navbar-container .navbar-mid .menu-button:hover {
    color: #000000;
  }
  .navbar-container .navbar-mid .navbar-list {
    top: 60%;
    z-index: 1050;
  }
  .navbar-container .navbar-mid .navbar-list.mobile-menu-open {
    visibility: visible;
    opacity: 100%;
  }
  .navbar-container .navbar-mid .navbar-list .nav-item {
    width: 100%;
    border: 1px solid black;
    height: 50px;
  }
  .navbar-container .navbar-mid .navbar-list .nav-item .nav-link {
    padding-top: 3px;
    padding-bottom: 2px;
    display: block;
    width: 100%;
    color: #000;
  }
  .navbar-container .navbar-mid .navbar-list .dropdown-content {
    position: static;
    box-shadow: none;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    padding: 0;
    font-size: 20px;
    text-align: center;
    z-index: 1060;
  }
  .navbar-container .navbar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .navbar-container .navbar-right .nav-right-button {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .navbar-container .navbar-right .nav-right-button li {
    margin-right: -10px;
  }
  .navbar-container .navbar-right .nav-right-button li .Donate-btn-nav {
    font-weight: 700;
    font-size: 0.9rem;
    position: relative;
    background: transparent;
    border: #000000 solid 1px;
    background-color: yellow;
    padding: 12px;
  }
}
.navbar-container:hover .menu-button {
  color: #000 !important;
}/*# sourceMappingURL=navbar.css.map */