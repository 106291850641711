.who-container {
  margin-top: -73px;
}
.who-container .section-who-heading {
  background-color: black;
  padding: 12% 5% 2%;
  font-weight: 700;
  color: white;
  font-size: 90px;
}
.who-container .section-who-subheading1 {
  margin-top: 5px;
  font-size: 45px;
  font-weight: 700;
  line-height: 49px;
  font-style: normal;
}
.who-container .line {
  width: 95%;
  height: 0.8px;
  background-color: rgb(0, 0, 0);
  margin-top: -40px;
}
.who-container .our-team-body {
  margin-left: 10px;
  margin-right: 50px;
  justify-content: space-between;
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 30px;
}
.who-container .our-team-body .our-team {
  display: block;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
  padding: 15px;
  height: 280px;
  width: 350px;
  margin-bottom: 30px;
  background-color: #ffffff;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.who-container .our-team-body .our-team .picture {
  display: inline-block;
  height: 150px;
  width: 150px;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
}
.who-container .our-team-body .our-team .picture img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(1.05);
  transition: all 0.9s ease 0s;
}
.who-container .our-team-body .our-team::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: #ffbf00;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}
.who-container .our-team-body .our-team:hover::before {
  height: 70%;
}
.who-container .our-team-body .our-team::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffbf00;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.who-container .our-team-body .our-team:hover img {
  box-shadow: 0 0 0 14px #ffffff;
  transform: scale(0.8);
}
.who-container .our-team-body .our-team .name {
  margin-top: -10px;
  font-weight: 600;
  font-size: 23px;
}
.who-container .our-team-body .our-team .title {
  margin-top: -15px;
  display: block;
  font-size: 20px;
  color: #4e5052;
  text-transform: capitalize;
}
.who-container .our-team-body .our-team .social {
  width: 100%;
  height: 40px;
  padding: 0;
  margin: 0;
  bottom: -40px;
  background-color: #ffbf00;
  position: absolute;
  left: 0;
  transition: all 0.5s ease 0s;
}
.who-container .our-team-body .our-team .social li {
  display: inline-block;
}
.who-container .our-team-body .our-team .social li a {
  display: block;
  padding: 10px;
  font-size: 15px;
  color: rgb(0, 0, 0);
  transition: all 0.3s ease 0s;
  text-decoration: none;
}
.who-container .our-team-body .our-team .social li a:hover {
  color: #ffbf00;
  background-color: #ffffff;
}
.who-container .our-team-body .our-team:hover .social {
  bottom: 0;
}
.who-container .team-members {
  display: grid;
  margin-right: 40px;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  margin-top: 10px;
  margin-bottom: 50px;
  gap: 13px;
  row-gap: 35px;
  position: relative;
}
.who-container .team-members .team-member {
  width: 260px;
  height: 350px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.who-container .team-members .team-member .image-card {
  max-width: 100%;
  max-height: 100%;
}
.who-container .team-members .team-member .image-card .image {
  width: 100%;
  height: 100%;
}
.who-container .team-members .team-member .member-name {
  margin-top: 2px;
  font-size: 20px;
  font-weight: 600;
}
.who-container .team-members .team-member .member-designation {
  margin-top: -20px;
  font-size: 18px;
}
.who-container .team-members .team-member .card-content {
  display: none;
  /* Hide the text by default */
}
.who-container .team-members .team-member:hover .card-content {
  display: block;
  /* Show the text when the card is hovered */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  /* Add a semi-transparent background */
  border-radius: 5px;
  padding: 1px;
}
.who-container .team-members .social-links {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30px;
  padding: 0;
  margin: 0;
  bottom: -10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ffbf00;
  position: absolute;
  left: 0;
  transition: all 0.5s ease 0s;
}
.who-container .team-members .social-links li {
  display: inline-block;
}
.who-container .team-members .social-links li a {
  display: block;
  padding: 10px;
  font-size: 15px;
  color: rgb(0, 0, 0);
  transition: all 0.3s ease 0s;
  text-decoration: none;
}
.who-container .team-members .social-links li a:hover {
  color: #ffbf00;
  background-color: #ffffff;
}

@media (max-width: 620px) {
  .who-container {
    background-color: #ebebeb;
    margin-top: -40px;
  }
  .who-container .section-who-heading {
    font-size: 52px;
    font-weight: 700;
    line-height: 47px;
    font-style: normal;
    padding-top: 100px;
    padding-bottom: 30px;
  }
  .who-container .section-who-subheading1 {
    margin-top: 5px;
    margin-left: 20px;
    font-size: 40px;
    font-weight: 700;
    line-height: 49px;
    font-style: normal;
  }
  .who-container .line {
    width: 90%;
    height: 0.5px;
    background-color: rgb(0, 0, 0);
    margin-top: -40px;
    margin-left: 20px;
  }
  .who-container .our-team-body {
    margin-left: 35px;
    margin-right: 40px;
    justify-content: space-between;
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    gap: 30px;
  }
  .who-container .our-team-body .our-team {
    display: block;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
    padding: 15px;
    height: 280px;
    width: 240px;
    margin-bottom: 30px;
    background-color: #ffffff;
    text-align: center;
    overflow: hidden;
    position: relative;
  }
  .who-container .our-team-body .our-team .picture {
    display: inline-block;
    height: 150px;
    width: 150px;
    margin-bottom: 30px;
    z-index: 1;
    position: relative;
  }
  .who-container .our-team-body .our-team .picture img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: scale(1.05);
    transition: all 0.9s ease 0s;
  }
  .who-container .our-team-body .our-team::before {
    content: "";
    width: 100%;
    height: 0;
    border-radius: 50%;
    background-color: #ffbf00;
    position: absolute;
    bottom: 135%;
    right: 0;
    left: 0;
    opacity: 0.9;
    transform: scale(3);
    transition: all 0.3s linear 0s;
  }
  .who-container .our-team-body .our-team:hover::before {
    height: 70%;
  }
  .who-container .our-team-body .our-team::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #ffbf00;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .who-container .our-team-body .our-team:hover img {
    box-shadow: 0 0 0 14px #ffffff;
    transform: scale(0.8);
  }
  .who-container .our-team-body .our-team .name {
    margin-top: -10px;
    font-weight: 600;
    font-size: 23px;
  }
  .who-container .our-team-body .our-team .title {
    margin-top: -15px;
    display: block;
    font-size: 20px;
    color: #4e5052;
    text-transform: capitalize;
  }
  .who-container .our-team-body .our-team .social {
    width: 100%;
    height: 40px;
    padding: 0;
    margin: 0;
    bottom: -40px;
    background-color: #ffbf00;
    position: absolute;
    left: 0;
    transition: all 0.5s ease 0s;
  }
  .who-container .our-team-body .our-team .social li {
    display: inline-block;
  }
  .who-container .our-team-body .our-team .social li a {
    display: block;
    padding: 10px;
    font-size: 15px;
    color: rgb(0, 0, 0);
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .who-container .our-team-body .our-team .social li a:hover {
    color: #ffbf00;
    background-color: #ffffff;
  }
  .who-container .our-team-body .our-team:hover .social {
    bottom: 0;
  }
  .who-container .team-members {
    display: grid;
    margin-left: 40px;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    margin-top: 10px;
    margin-bottom: 50px;
    gap: 13px;
    row-gap: 35px;
    position: relative;
  }
  .who-container .team-members .team-member {
    width: 260px;
    height: 350px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .who-container .team-members .team-member .image-card {
    max-width: 100%;
    max-height: 100%;
  }
  .who-container .team-members .team-member .image-card .image {
    width: 100%;
    height: 100%;
  }
  .who-container .team-members .team-member .member-name {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 600;
  }
  .who-container .team-members .team-member .member-designation {
    margin-top: -20px;
    font-size: 18px;
  }
  .who-container .team-members .team-member .card-content {
    display: none;
    /* Hide the text by default */
  }
  .who-container .team-members .team-member:hover .card-content {
    display: block;
    /* Show the text when the card is hovered */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 8px;
    background-color: rgba(255, 255, 255, 0.3);
    /* Add a semi-transparent background */
    border-radius: 5px;
    padding: 1px;
  }
  .who-container .team-members .social-links {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 30px;
    padding: 0;
    margin: 0;
    bottom: -10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #ffbf00;
    position: absolute;
    left: 0;
    transition: all 0.5s ease 0s;
  }
  .who-container .team-members .social-links li {
    display: inline-block;
  }
  .who-container .team-members .social-links li a {
    display: block;
    padding: 10px;
    font-size: 15px;
    color: rgb(0, 0, 0);
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .who-container .team-members .social-links li a:hover {
    color: #ffbf00;
    background-color: #ffffff;
  }
}
@media (min-width: 621px) and (max-width: 1300px) {
  .who-container {
    margin-top: -33px;
  }
  .who-container .section-who-heading {
    font-size: 40px;
  }
  .who-container .our-team-body {
    display: grid;
    margin-left: 10%;
    margin-right: 20%;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
  }
  .who-container .our-team-body .our-team {
    display: block;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
    padding: 15px;
    height: 280px;
    width: 200px;
    margin-bottom: 30px;
    background-color: #ffffff;
    text-align: center;
    overflow: hidden;
    position: relative;
  }
  .who-container .team-members {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0;
    margin-right: 0;
  }
}/*# sourceMappingURL=team.css.map */