.community-container .community-header {
  background-color: black;
  padding: 12% 5% 2%;
  font-weight: 700;
  font-size: 90px;
  color: #ffffff;
}
.community-container .frameinsta {
  margin-left: 50px;
  margin-top: 50px;
}/*# sourceMappingURL=community.css.map */