.about-container {
  .about-header {
    background-color: black;
    padding: 12% 5% 2%;
    font-weight: 700;
    color: white;
    font-size: 4rem;
  }

  .about-nav {
    position: sticky;
    top: 50px; 
    background-color: #4d4c4c; 
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); 
    display: flex;
    border: 1px solid #000; 
    align-items: center;
    padding: 9.8px 0;
    height: 0.9rem;
    padding-left: 30px;
    margin: auto;
    margin-top: 1px;
    margin-bottom: 5px;
    z-index: 500; 
  }

  .about-nav-btn {
    text-decoration: none;
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    padding: 8px 17px;
    border: 1px solid #000; 
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: rgb(0, 0, 0); 
    }
  }

  .mission-section {
    display: flex;
    .mission-image {
      width: 50%;
    }
    .content-mission {
      padding: 2% 4%;

      .mission-heading {
        font-size: 3rem;
        font-weight: 700;
      }
      .line {
        width: 95%;
        height: 1px;
        background-color: rgb(0, 0, 0);
        margin-top: -30px;
        margin-bottom: 20px;
      }

      .mission-details {
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 27px;
        color: #555;
        
        padding-right: 70px;
      }
    }
    .content-vision {
      padding: 5% 5%;
      text-align: right;
      .vision-heading {
        font-size: 3rem;
        font-weight: 700;
      }
      .line {
        width: 98%;
        height: 1px;
        background-color: rgb(0, 0, 0);
        margin-top: -30px;
        margin-bottom: 20px;
      }

      .vision-details {
        text-align: right;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 27px;
        color: #555;
      }
    }
  }
  .work-section {
    display: flex;
    .work-mission {
      padding: 20px 30px;

      .work-heading {
        font-size: 50px;
        font-weight: 700;
      }
      .line {
        width: 100%;
        height: 1px;
        background-color: rgb(0, 0, 0);
        margin-top: -30px;
        margin-bottom: 20px;
      }
      .work-details {
        font-size: 21px;
        font-weight: 400;
        line-height: 27px;
        color: #555;
        text-align: justify;
        padding-right: 70px;
      }
    }
    .work-image {
      max-width: 50%;
    }
  }
  .principles-section {
    background-color: #2846e6;
    padding-left: 40px;
    padding-right: 40px;

    .principles-heading {
      padding-top: 40px;
      font-size: 52px;
      line-height: 48px;
      color: #ffffff;
    }
    .line {
      width: 100%;
      height: 2px;
      background-color: white;
      margin-top: -30px;
    }
    .principles-data {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      padding-bottom: 40px;

      .principles-card {
        padding: 20px;
        border-radius: 8px;
        color: #ffffff;

        .principles-card-heading {
          font-size: 2.5rem;
          font-weight: bold;
        }

        .principles-card-details {
          font-size: 21px;
          margin-top: 10px;
        }
      }
    }
  }
  .who-section {
    background-color: #ebebeb;
    padding-left: 40px;
    padding-top: 60px;
    padding-bottom: 40px;

    .section-who-heading {
      font-size: 52px;
      font-weight: 700;
      line-height: 47px;
      font-style: normal;
    }
    .section-who-subheading1 {
      margin-top: 5px;
      font-size: 45px;
      font-weight: 700;
      line-height: 49px;
      font-style: normal;
    }
    .line {
      width: 95%;
      height: 0.8px;
      background-color: rgb(0, 0, 0);
      margin-top: -40px;
    }
    .names-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      margin-right: 44px;
      margin-top: 50px;
    
      .name-item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        background-color: #ffc400;
        text-align: center;
        font-size: 1.22rem;
        color: #000000;
        transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
        transform: translateY(0); /* Initial position */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Initial box-shadow */
    
        &:hover {
          background-color: #ff9900; /* Change background color on hover */
          transform: translateY(-5px); /* Move up slightly on hover */
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Apply a stronger box-shadow on hover */
          color: #ffffff; /* Change text color to white on hover */
        }
      }
    }
    
  }
  .reports-section {
    background-color: #ebebeb;
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 0px;

    .report-heading {
      font-size: 52px;
      font-weight: 700;
      line-height: 47px;
      font-style: normal;
      margin-bottom: 10px;
    }
    .line {
      width: 95%;
      height: 0.8px;
      background-color: rgb(0, 0, 0);
    }
    .report-drive {
      width: 100%;
      height: 500px;
      overflow: hidden;
    }
  }
  .partners-section {
    background-color: #ebebeb;
    padding-left: 40px;
    padding-top: 5px;
    padding-bottom: 40px;

    .partner-heading {
      font-size: 52px;
      font-weight: 700;
      line-height: 47px;
      font-style: normal;
      margin-bottom: 10px;
    }
    .line {
      width: 95%;
      height: 0.8px;
      background-color: rgb(0, 0, 0);
    }
    .partners-class {
      display: grid;
      margin-top: 20px;
      grid-template-columns: repeat(4, 1fr);

      img {
        width: 100%;
      }

      .member-name,
      .member-designation {
        margin-left: auto;
        text-align: center;
      }
    }
  }
}
@media (max-width: 1440px) {
  .about-container {
    .about-header {
      
      font-size: 4rem; 
    }
  
    .about-nav {
      
      top: 50px; /* Adjust top spacing as needed */
      
    }
  
    .about-nav-btn {
      
      font-size: 1rem; 
      
    }
  
    .mission-section {
      

      
    }
  
    .work-section {
      
      .work-heading {
        
        font-size: 50px; 
      }
  
      .work-details {
        
        font-size: 21px; 
      }
  
      
    }
  
    .principles-section {
      
      .principles-heading {
       
        font-size: 52px;
      }
      .principles-data {
        grid-template-columns: repeat(2, 1fr);
      .principles-card {
        
        .principles-card-heading {
         
          font-size: 2.5rem;
        }
  
        .principles-card-details {
         
          font-size: 21px; 
        }}
      }
  
    
    }
  
    .who-section {
    
      .section-who-heading {
    
        font-size: 52px;
      }
  
      .section-who-subheading1 {
       
        font-size: 45px; 
      }
  
      
    }
  
    .names-grid {
     
      .name-item {
       
        font-size: 1.22rem; 
      }
    }
  
    .reports-section {
      
      .report-heading {
        
        font-size: 52px;
      }
  
  
    }
  
    .partners-section {
      
      .partner-heading {
        
        font-size: 52px; 
      }
  
      .partners-class {
        
        img {
          
          width: 100%; /* Adjust image width as needed */
        }
      }
    }
    }
}
@media (max-width: 1024px) {
  .about-container {
    .about-header {
      
      font-size: 4rem; 
    }
  
    .about-nav {
      
      top: 50px; /* Adjust top spacing as needed */
      
    }
  
    .about-nav-btn {
      
      font-size: 1rem; 
      
    }
  
    .mission-section {
      

      
    }
  
    .work-section {
      
      .work-heading {
        
        font-size: 50px; 
      }
  
      .work-details {
        
        font-size: 21px; 
      }
  
      
    }
  
    .principles-section {
      
      .principles-heading {
       
        font-size: 52px;
      }
      .principles-data {
        grid-template-columns: repeat(2, 1fr);
      .principles-card {
        
        .principles-card-heading {
         
          font-size: 2.5rem;
        }
  
        .principles-card-details {
         
          font-size: 21px; 
        }}
      }
  
    
    }
  
    .who-section {
    
      .section-who-heading {
    
        font-size: 52px;
      }
  
      .section-who-subheading1 {
       
        font-size: 45px; 
      }
  
      
    }
  
    .names-grid {
     
      .name-item {
       
        font-size: 1.22rem; 
      }
    }
  
    .reports-section {
      
      .report-heading {
        
        font-size: 52px;
      }
  
  
    }
  
    .partners-section {
      
      .partner-heading {
        
        font-size: 52px; 
      }
  
      .partners-class {
        
        img {
          
          width: 100%; /* Adjust image width as needed */
        }
      }
    }
    }
}
@media (min-width:769px) and (max-width:915px) {
  .about-container {
    max-width: 100%;
    .about-header {
      padding-top: 20%;
      font-size: 4rem;
      text-align: center;
      padding-bottom: 5%;
    }
    .about-nav {
      display: none;
    }

    .mission-section {
      flex-direction: column;
      .mission-image {
        width: 100%;
      }
      .content-mission {
        .mission-heading {
          text-align: center;
          font-size: 3rem;
        }
        .mission-details {
          text-align: center;
          font-size: 1.2rem;
          padding-right: 0;
        }
      }
      .content-vision {
        .vision-heading {
          font-size: 3rem;
          font-weight: 700;
          text-align: center;
        }
        .vision-details {
          text-align: center;
          font-size: 1.2rem;
        }
      }
    }
    .work-section {
      flex-direction: column;
      text-align: center;
      .work-mission {
        .work-heading {
          font-size: 3rem;
          font-weight: 700;
        }
        .work-details {
          font-size: 1.2rem;
          padding-right: 5px;
        }
      }
      .work-image {
        max-width: 100%;
      }
    }
    .principles-section {
      padding-left: 20px;
      padding-right: 20px;
      .principles-heading {
        padding-top: 40px;
        font-size: 2.5rem;
      }
      .line {
        width: 100%;
        height: 2px;
        margin-top: -30px;
      }
      .principles-data {
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        padding-bottom: 40px;
        .principles-card {
          .principles-card-heading {
            font-size: 1.5rem;
          }
          .principles-card-details {
            font-size: 1.3rem;
          }
        }
      }
    }
    .who-section {
      padding-left: 20px;
      padding-top: 2px;
      .section-who-heading {
        font-size: 2.5rem;
      }
      .section-who-subheading1 {
        font-size: 1.9rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
        margin-top: -30px;
        margin-bottom: 40px;
      }
      .names-grid {
        margin-top: -20px;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        justify-content: center;
        margin-left: 2%;
        .name-item {
          padding: 9px;
          background-color: #ffc400;
          text-align: center;
          font-size: 1.02rem;
        }
        
      }
    }
    .reports-section {
      padding-left: 20px;
      padding-top: 2px;
      .report-heading {
        font-size: 2.8rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
      }
      .report-drive {
        width: 100%;
        height: 300px;
        overflow: visible;
      }
    }
    .partners-section {
      padding-left: 20px;
      padding-top: 1px;
      .partner-heading {
        font-size: 2.5rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
      }
      .partners-class {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-left: 10px;
        margin-right: 20px;
        gap: 5%;
        img {
          width: 100%;
        }
        .member-name,
        .member-designation {
          margin-left: auto;
          text-align: center;
        }
      }
    }
  }
}
@media (max-width: 769px) {
  .about-container {
    max-width: 100%;
    .about-header {
      padding-top: 20%;
      font-size: 4rem;
      text-align: center;
      padding-bottom: 5%;
    }
    .about-nav {
      display: none;
    }

    .mission-section {
      flex-direction: column;
      .mission-image {
        width: 100%;
      }
      .content-mission {
        .mission-heading {
          text-align: center;
          font-size: 3rem;
        }
        .mission-details {
          text-align: center;
          font-size: 1.2rem;
          padding-right: 0;
        }
      }
      .content-vision {
        .vision-heading {
          font-size: 3rem;
          font-weight: 700;
          text-align: center;
        }
        .vision-details {
          text-align: center;
          font-size: 1.2rem;
        }
      }
    }
    .work-section {
      flex-direction: column;
      text-align: center;
      .work-mission {
        .work-heading {
          font-size: 3rem;
          font-weight: 700;
        }
        .work-details {
          font-size: 1.2rem;
          padding-right: 5px;
        }
      }
      .work-image {
        max-width: 100%;
      }
    }
    .principles-section {
      padding-left: 20px;
      padding-right: 20px;
      .principles-heading {
        padding-top: 40px;
        font-size: 2.5rem;
      }
      .line {
        width: 100%;
        height: 2px;
        margin-top: -30px;
      }
      .principles-data {
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        padding-bottom: 40px;
        .principles-card {
          .principles-card-heading {
            font-size: 1.5rem;
          }
          .principles-card-details {
            font-size: 1.3rem;
          }
        }
      }
    }
    .who-section {
      padding-left: 20px;
      padding-top: 2px;
      .section-who-heading {
        font-size: 2.5rem;
      }
      .section-who-subheading1 {
        font-size: 1.9rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
        margin-top: -30px;
        margin-bottom: 40px;
      }
      .names-grid {
        margin-top: -20px;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        justify-content: center;
        margin-left: 2%;
        .name-item {
          padding: 9px;
          background-color: #ffc400;
          text-align: center;
          font-size: 1.02rem;
        }
        
      }
    }
    .reports-section {
      padding-left: 20px;
      padding-top: 2px;
      .report-heading {
        font-size: 2.8rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
      }
      .report-drive {
        width: 100%;
        height: 300px;
        overflow: visible;
      }
    }
    .partners-section {
      padding-left: 20px;
      padding-top: 1px;
      .partner-heading {
        font-size: 2.5rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
      }
      .partners-class {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-left: 10px;
        margin-right: 20px;
        gap: 5%;
        img {
          width: 100%;
        }
        .member-name,
        .member-designation {
          margin-left: auto;
          text-align: center;
        }
      }
    }
  }
}
@media (max-width: 427px) {
  .about-container {
    max-width: 100%;
    .about-header {
      padding-top: 20%;
      font-size: 4rem;
      text-align: center;
      padding-bottom: 5%;
    }
    .about-nav {
      display: none;
    }

    .mission-section {
      flex-direction: column;
      .mission-image {
        width: 100%;
      }
      .content-mission {
        .mission-heading {
          text-align: center;
          font-size: 3rem;
        }
        .mission-details {
          text-align: center;
          font-size: 1.2rem;
          padding-right: 0;
        }
      }
      .content-vision {
        .vision-heading {
          font-size: 3rem;
          font-weight: 700;
          text-align: center;
        }
        .vision-details {
          text-align: center;
          font-size: 1.2rem;
        }
      }
    }
    .work-section {
      flex-direction: column;
      text-align: center;
      .work-mission {
        .work-heading {
          font-size: 3rem;
          font-weight: 700;
        }
        .work-details {
          font-size: 1.2rem;
          padding-right: 5px;
        }
      }
      .work-image {
        max-width: 100%;
      }
    }
    .principles-section {
      padding-left: 20px;
      padding-right: 20px;
      .principles-heading {
        padding-top: 40px;
        font-size: 2.5rem;
      }
      .line {
        width: 100%;
        height: 2px;
        margin-top: -30px;
      }
      .principles-data {
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        padding-bottom: 40px;
        .principles-card {
          .principles-card-heading {
            font-size: 1.5rem;
          }
          .principles-card-details {
            font-size: 1.3rem;
          }
        }
      }
    }
    .who-section {
      padding-left: 20px;
      padding-top: 2px;
      .section-who-heading {
        font-size: 2.5rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
        margin-top: -30px;
        margin-bottom: 40px;
      }
      .section-who-subheading1 {
        font-size: 1.9rem;
      }
      
      .names-grid {
        margin-top: -20px;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        justify-content: center;
        margin-left: 10%;
        .name-item {
          padding: 9px;
          background-color: #ffc400;
          text-align: center;
          font-size: 1.02rem;
        }
        
      }
    }
    .reports-section {
      padding-left: 20px;
      padding-top: 2px;
      .report-heading {
        font-size: 2.8rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
      }
      .report-drive {
        width: 100%;
        height: 700px;
        overflow: visible;
      }
    }
    .partners-section {
      padding-left: 20px;
      padding-top: 1px;
      .partner-heading {
        font-size: 2.5rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
      }
      .partners-class {
        grid-template-columns: repeat(1, 1fr);
        margin-left: 10px;
        margin-right: 20px;
        img {
          width: 100%;
        }
        .member-name,
        .member-designation {
          margin-left: auto;
          text-align: center;
        }
      }
    }
  }
}
@media (max-width: 376px) {
  .about-container {
    max-width: 100%;
    .about-header {
      padding-top: 20%;
      font-size: 4rem;
      text-align: center;
      padding-bottom: 5%;
    }
    .about-nav {
      display: none;
    }

    .mission-section {
      flex-direction: column;
      .mission-image {
        width: 100%;
      }
      .content-mission {
        .mission-heading {
          text-align: center;
          font-size: 3rem;
        }
        .mission-details {
          text-align: center;
          font-size: 1.2rem;
          padding-right: 0;
        }
      }
      .content-vision {
        .vision-heading {
          font-size: 3rem;
          font-weight: 700;
          text-align: center;
        }
        .vision-details {
          text-align: center;
          font-size: 1.2rem;
        }
      }
    }
    .work-section {
      flex-direction: column;
      text-align: center;
      .work-mission {
        .work-heading {
          font-size: 3rem;
          font-weight: 700;
        }
        .work-details {
          font-size: 1.2rem;
          padding-right: 5px;
        }
      }
      .work-image {
        max-width: 100%;
      }
    }
    .principles-section {
      padding-left: 20px;
      padding-right: 20px;
      .principles-heading {
        padding-top: 40px;
        font-size: 2.5rem;
      }
      .line {
        width: 100%;
        height: 2px;
        margin-top: -30px;
      }
      .principles-data {
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        padding-bottom: 40px;
        .principles-card {
          .principles-card-heading {
            font-size: 1.5rem;
          }
          .principles-card-details {
            font-size: 1.3rem;
          }
        }
      }
    }
    .who-section {
      padding-left: 20px;
      padding-top: 2px;
      .section-who-heading {
        font-size: 2.5rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
        margin-top: -30px;
        margin-bottom: 40px;
      }
      .section-who-subheading1 {
        font-size: 1.9rem;
      }
      
      .names-grid {
        margin-top: -20px;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        .name-item {
          padding: 9px;
          background-color: #ffc400;
          text-align: center;
          font-size: 1.22rem;
        }
      }
    }
    .reports-section {
      padding-left: 20px;
      padding-top: 2px;
      .report-heading {
        font-size: 2.8rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
      }
      .report-drive {
        width: 100%;
        height: 700px;
        overflow: visible;
      }
    }
    .partners-section {
      padding-left: 20px;
      padding-top: 1px;
      .partner-heading {
        font-size: 2.5rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
      }
      .partners-class {
        grid-template-columns: repeat(1, 1fr);
        margin-left: 10px;
        margin-right: 20px;
        img {
          width: 100%;
        }
        .member-name,
        .member-designation {
          margin-left: auto;
          text-align: center;
        }
      }
    }
  }
}
@media (max-width: 321px) {
  .about-container {
    max-width: 100%;
    .about-header {
      padding-top: 25%;
      font-size: 4rem;
      text-align: center;
    }
    .about-nav {
      display: none;
    }

    .mission-section {
      flex-direction: column;
      .mission-image {
        width: 100%;
      }
      .content-mission {
        .mission-heading {
          text-align: center;
          font-size: 3rem;
        }
        .mission-details {
          text-align: center;
          font-size: 1.2rem;
          padding-right: 0;
        }
      }
      .content-vision {
        .vision-heading {
          font-size: 3rem;
          font-weight: 700;
          text-align: center;
        }
        .vision-details {
          text-align: center;
          font-size: 1.2rem;
        }
      }
    }
    .work-section {
      flex-direction: column;
      text-align: center;
      .work-mission {
        .work-heading {
          font-size: 3rem;
          font-weight: 700;
        }
        .work-details {
          font-size: 1.2rem;
          padding-right: 5px;
        }
      }
      .work-image {
        max-width: 100%;
      }
    }
    .principles-section {
      padding-left: 20px;
      padding-right: 20px;
      .principles-heading {
        padding-top: 40px;
        font-size: 2.5rem;
      }
      .line {
        width: 100%;
        height: 2px;
        margin-top: -40px;
      }
      .principles-data {
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        padding-bottom: 40px;
        .principles-card {
          .principles-card-heading {
            font-size: 1.5rem;
          }
          .principles-card-details {
            font-size: 1.3rem;
          }
        }
      }
    }
    .who-section {
      padding-left: 20px;
      padding-top: 2px;
      .section-who-heading {
        font-size: 2.5rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
        margin-top: -30px;
        margin-bottom: 40px;
      }
      .section-who-subheading1 {
        font-size: 1.9rem;
      }
      
      .names-grid {
        margin-top: -20px;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        .name-item {
          padding: 9px;
          background-color: #ffc400;
          text-align: center;
          font-size: 1rem;
        }
      }
    }
    .reports-section {
      padding-left: 20px;
      padding-top: 2px;
      .report-heading {
        font-size: 2.8rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
      }
      .report-drive {
        width: 100%;
        height: 700px;
        overflow: visible;
      }
    }
    .partners-section {
      padding-left: 20px;
      padding-top: 1px;
      .partner-heading {
        font-size: 2.5rem;
      }
      .line {
        width: 95%;
        height: 0.8px;
        background-color: rgb(0, 0, 0);
      }
      .partners-class {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin-left: 10px;
        margin-right: 20px;
        img {
          width: 100%;
        }
        .member-name,
        .member-designation {
          margin-left: auto;
          text-align: center;
        }
      }
    }
  }
}




