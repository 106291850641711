.TC-container {
    background-color: #f7f7f7;
    color: #333;
  
    .TC-header {
      background-color: black;
      padding: 12% 5% 2%;
      font-weight: 700;
      color: white;
      font-size: 90px;
    }
  
    .TC-content {
      padding: 40px 60px;
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 40px;
  
      h2 {
        font-size: 28px;
        color: #333;
        margin-bottom: 20px;
        text-align: center;
      }
  
      ul {
        padding-left: 30px;
        list-style-type: disc;
      }
  
      li {
        margin-bottom: 10px;
      }
  
      p {
        margin-bottom: 20px;
      }
  
      a {
        color: #0066cc;
        text-decoration: underline;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .TC-container  {
        .TC-header {

        }
    }
  }

  @media screen and (max-width: 768px) {
    .TC-container  {
        .TC-header {
            padding-top:95px;
            padding-left: 20px;
            font-weight: 700;
            font-size: 45px;
            text-align: center;
            padding-bottom: 45px;
        }
        .TC-content {
            padding: 20px 10px;  
        }
    }
  }

  @media screen and (max-width: 425px) {
    .TC-container  {
        .TC-header {
            padding-top:95px;
            padding-left: 20px;
            font-weight: 700;
            font-size: 45px;
            text-align: center;
            padding-bottom: 45px;
        }
        .TC-content {
            padding: 20px 10px;  
        }
    }
  }

  @media screen and (max-width: 375px) {
    .TC-container  {
        .TC-header {
            padding-top:95px;
            padding-left: 20px;
            font-weight: 700;
            font-size: 45px;
            text-align: center;
            padding-bottom: 45px;
        }
        .TC-content {
            padding: 20px 10px;  
        }
    }
  }

  @media screen and (max-width: 320px) {
    .TC-container  {
        .TC-header {
            padding-top:95px;
            padding-left: 20px;
            font-weight: 700;
            font-size: 45px;
            text-align: center;
            padding-bottom: 45px;
        }
        .TC-content {
            padding: 20px 10px;  
        }
    }
  }