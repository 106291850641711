 .navbar-container {
  position: fixed;
  max-width: 100%;
  max-height: 50px;
  top: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: transparent;
  backdrop-filter: blur(10px);
  align-items: center;
  z-index: 1000;
  border-bottom: #ffffff solid 1px;
  transition: background-color 0.3s ease, border-color 0.3s ease;

 &:hover {
    background-color: rgb(255, 255, 255);
    border-bottom: #000000 solid 1px;

    .nav-item {
      border-right: rgb(0, 0, 0) solid 1px;

      .nav-link {
        color: rgb(0, 0, 0) !important; /* Change the text color to black on hover */
      }
    }
  }

  &.scrolled {
    background-color: #ffffff;
    border-bottom: #000000 solid 1px;
    top:0;
    .nav-item {
      border-right-color: black; /* Change the border color to black when scrolled */

      .nav-link {
        color: #000000 !important; /* Change the text color to black when scrolled */
      }
    }
  }

  .navbar-left {
    display: flex;
    justify-content: flex-start;

    .navbar-brand img {
      padding-left: 1%; 
      width: 155px;
    }
  }
.navbar-mid {
  display: flex;
  height: 50px;
  margin-left: 13%;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  border: #ffffff solid 1px;
  &:hover{
    border-color: #000000;
    .nav-item {
      border-right-color: black; 
      .nav-link {
        color: rgb(0, 0, 0) !important;
      }
    }
  }
  &.scrolled {
    border-color: #000000;
  }

  .menu-button {
    display: none;
    color: #12d9a7;
    z-index: 1100;
    cursor: pointer;
    &.scrolled {
      color: #07fd03;
    }
    &.hover {
      color: #000000;
    }
  }
}
.navbar-list {
  margin-left: -40px;
  display: flex;
  align-items: center;
  z-index: 1050;
  .nav-item {
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    line-height: 15px;
    font-weight: 500;
    background-color: transparent;
    
    border: none;
    border-right: rgb(255, 255, 255) solid 1px;
    position: relative;
    &.scrolled {
      border-right: rgb(0, 0, 0) solid 1px;
    }

    &::before {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      background-color: rgb(255, 94, 0);
      transition: height 1s ease, width 1s ease, background-color 0.3s ease;
    }

    .nav-link {
      padding-top: 10px;
      padding-bottom: 5px;
      align-self: center;
      justify-content: center;
      color: #f40808;
      transition: color 1s ease;

      &.scrolled {
        color: #f70303;
      }
    }

    &:hover {
      border-right: rgb(0, 0, 0) solid 1px;

      .nav-link {
        color: rgb(255, 255, 255);
      }

      &::before {
        height: 3px;
        width: 100%;
        background-color: hsl(0, 0%, 0%);
        border-right: rgb(0, 0, 0) solid 1px;
      }
    }
  }
}
.nav-item {
  color: #000000;
  border-right: #000000 solid 1px;
  background-color: yellow;
  .nav-link {
    color: rgb(254, 254, 254) !important;
  }
}
.nav-item:hover {
  border-right: #000000 solid 1px;
  background-color: yellow;
  .nav-link {
    color: rgb(0, 0, 0) !important;
  }
}
  .navbar-right {
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .nav-right-button {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      li {
        margin-right: 30px;
        .Donate-btn-nav {
          font-weight: 700;
          font-size: 1rem;
          position: relative;
          background: transparent;
          border: #000000 solid 1px;
          background-color: yellow;
          padding: 29%;
          transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 0;
            background-color: blue;
            transition: height 0.9s ease;
          }

          &:hover {
            background-color: blue;
            color: #ffffff;

            &::before {
              height: 100%;
              z-index: -1000;
            }
          }
        }
      }
    }
  }
  .dropdown {
    position: relative;
    display: inline-block;
    
    &:hover .dropdown-content {
      display: block;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 140px;
    min-height: 5px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1060;

    .nav-link {
      color: #000;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      font-size: 0.9rem;
      font-weight: lighter;
      &:hover{
        background-color: yellow;
      }
    }
  }
}
.navbar-brand img.default-logo,
.navbar-brand img.scrolled-logo {
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

// Show scrolled logo on hover over the navbar-container
.navbar-container:hover .navbar-brand img.scrolled-logo {
  opacity: 0;
}

// Hide default logo on hover over the navbar-container
.navbar-container:hover .navbar-brand img.default-logo {
  opacity: 0;
}

.navbar-container:hover .navbar-brand img.scrolled-logo {
  opacity: 1 !important;
}

.navbar-container:hover .navbar-brand img.default-logo {
  opacity: 0 !important;
}
@media (max-width:1440px) {
    .navbar-container {
    position: fixed;
    max-width: 100%;
    max-height: 50px;
    top: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background: transparent;
    backdrop-filter: blur(10px);
    align-items: center;
    z-index: 1000;
    border-bottom: #ffffff solid 1px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  
   &:hover {
      background-color: rgb(255, 255, 255);
      border-bottom: #000000 solid 1px;
  
      .nav-item {
        border-right: rgb(0, 0, 0) solid 1px;
  
        .nav-link {
          color: rgb(0, 0, 0) !important; /* Change the text color to black on hover */
        }
      }
    }
  
    &.scrolled {
      background-color: #ffffff;
      border-bottom: #000000 solid 1px;
      top:0;
      .nav-item {
        border-right-color: black; /* Change the border color to black when scrolled */
  
        .nav-link {
          color: #000000 !important; /* Change the text color to black when scrolled */
        }
      }
    }
  
    .navbar-left {
      display: flex;
      justify-content: flex-start;
  
      .navbar-brand img {
        padding-left: 1%; 
        width: 155px;
      }
    }
  .navbar-mid {
    display: flex;
    height: 50px;
    margin-left: 18%;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    border: #ffffff solid 1px;
    &:hover{
      border-color: #000000;
      .nav-item {
        border-right-color: black; 
        .nav-link {
          color: black !important;
        }
      }
    }
    &.scrolled {
      border-color: #000000;
    }
  
    .menu-button {
      display: none;
      color: #0fa4e9;
      z-index: 1100;
      &.scrolled {
        color: #ea0909;
      }
      &.hover {
        color: #000000;
      }
    }
  }
  .navbar-list {
    margin-left: -40px;
    display: flex;
    align-items: center;
    z-index: 1050;
    .nav-item {
      height: 50px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      line-height: 15px;
      font-weight: 500;
      background-color: transparent;
      
      border: none;
      border-right: rgb(255, 255, 255) solid 1px;
      position: relative;
      &.scrolled {
        border-right: rgb(0, 0, 0) solid 1px;
      }
  
      &::before {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        background-color: yellow;
        transition: height 1s ease, width 1s ease, background-color 0.3s ease;
      }
  
      .nav-link {
        padding-top: 10px;
        padding-bottom: 5px;
        align-self: center;
        justify-content: center;
        color: #ffffff;
        transition: color 1s ease;
  
        &.scrolled {
          color: #000000;
        }
      }
  
      &:hover {
        border-right: rgb(0, 0, 0) solid 1px;
  
        .nav-link {
          color: rgb(255, 255, 255);
        }
  
        &::before {
          height: 3px;
          width: 100%;
          background-color: hsl(0, 0%, 0%);
          border-right: rgb(0, 0, 0) solid 1px;
        }
      }
    }
  }
    }
    }
@media (max-width:1024px) {
    .navbar-container {
    position: fixed;
    max-width: 100%;
    max-height: 50px;
    top: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background: transparent;
    backdrop-filter: blur(10px);
    align-items: center;
    z-index: 1000;
    border-bottom: #ffffff solid 1px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  
   &:hover {
      background-color: rgb(255, 255, 255);
      border-bottom: #000000 solid 1px;
  
      .nav-item {
        border-right: rgb(0, 0, 0) solid 1px;
  
        .nav-link {
          color: rgb(0, 0, 0) !important; /* Change the text color to black on hover */
        }
      }
    }
  
    &.scrolled {
      background-color: #ffffff;
      border-bottom: #000000 solid 1px;
      top:0;
      .nav-item {
        border-right-color: black; /* Change the border color to black when scrolled */
  
        .nav-link {
          color: #000000 !important; /* Change the text color to black when scrolled */
        }
      }
    }
  
    .navbar-left {
      display: flex;
      justify-content: flex-start;
  
      .navbar-brand img {
        padding-left: 1%; 
        width: 155px;
      }
    }
  .navbar-mid {
    display: flex;
    height: 50px;
    margin-left: 18%;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    border: #ffffff solid 1px;
    &:hover{
      border-color: #000000;
      .nav-item {
        border-right-color: black; 
        .nav-link {
          color: black !important;
        }
      }
    }
    &.scrolled {
      border-color: #000000;
    }
  
    .menu-button {
      display: none;
      color: #ffffff;
      z-index: 1100;
      &.scrolled {
        color: #000000;
      }
      &.hover {
        color: #000000;
      }
    }
  }
  .navbar-list {
    margin-left: -40px;
    display: flex;
    align-items: center;
    z-index: 1050;
    .nav-item {
      height: 50px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      line-height: 15px;
      font-weight: 500;
      background-color: transparent;
      
      border: none;
      border-right: rgb(255, 255, 255) solid 1px;
      position: relative;
      &.scrolled {
        border-right: rgb(0, 0, 0) solid 1px;
      }
  
      &::before {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        background-color: yellow;
        transition: height 1s ease, width 1s ease, background-color 0.3s ease;
      }
  
      .nav-link {
        padding-top: 10px;
        padding-bottom: 5px;
        align-self: center;
        justify-content: center;
        color: #ffffff;
        transition: color 1s ease;
  
        &.scrolled {
          color: #000000;
        }
      }
  
      &:hover {
        border-right: rgb(0, 0, 0) solid 1px;
  
        .nav-link {
          color: rgb(255, 255, 255);
        }
  
        &::before {
          height: 3px;
          width: 100%;
          background-color: hsl(0, 0%, 0%);
          border-right: rgb(0, 0, 0) solid 1px;
        }
      }
    }
  }
    }
    }
    @media (min-width:769px) and (max-width:886px) {
      .navbar-container {
        max-width: 100%;
        backdrop-filter: blur(0px);
        top: 17.2px;
        left: 0;
        right: 0;
        z-index: 1000;
        &.scrolled {
          top:0;
        }
        .navbar-left {
          display: flex;
          justify-content: flex-start;
          align-items: center; 
          
          .navbar-brand img {
            margin-top: 0.2rem;
            width: 130px;
          }
        }
        
    
        .navbar-mid {
          display: flex;
          height: 60px;
          margin-left: auto;
          flex-grow: 0;
          justify-content: space-between;
          border: none;
          padding: 0;
        
          .menu-button {
            background: transparent;
            border: none;
            font-weight: 600;
            margin-left: 10px;
            padding: 13px 13px;
            display: block;
            cursor: pointer;
            font-size: 16px;
            color: #ffffff;
            z-index: 1100;
        &.scrolled {
          color: #000000;
        }
        &:hover {
          color: #000000;
        }
          }
        
          .navbar-list {
            display: grid;
            flex-direction: column;
            position: absolute;
            top: 70%;
            border: 1px solid black;
            left: 0;
            background-color: #fffffffe; // Background color for the entire navbar list
            padding: auto;
            visibility: hidden;
            z-index: 1050;
            opacity: 100%;
            transition: visibility 0s, opacity 0.5s linear;
            width: 100%;
          
            &.mobile-menu-open {
              visibility: visible;
              opacity: 100%;
            }
          
            .nav-item {
              width: 100%; // Ensure full width for each nav item
              border: 1px solid black;
              height: 50px;
        
              .nav-link {
                padding-top: 3px;
                padding-bottom: 2px;
                display: block;
                width: 100%;
                color: #000; // Text color for navbar items
              }
            }
          
            .dropdown-content {
              position: static; 
              box-shadow: none;
              width: 100%; // Ensure full width for dropdown content
              background-color: #ffffff00; // Same background as navbar list
              padding: 0; // Remove additional padding
              font-size: 20px;
              text-align: center;
              z-index: 1060;
            }
          }
        }
        .navbar-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          
          .nav-right-button {
            list-style: none;
            display: flex;
            margin: 0;
            padding: 0;
      
            li {
              margin-right: auto;
      
              .Donate-btn-nav {
                font-weight: 700;
                font-size: 16px;
                position: relative;
                background: transparent;
                border: #000000 solid 1px;
                background-color: yellow;
                padding: 12px;
              }
            }
          }
        }
      }
    }
    .navbar-container:hover .menu-button {
      color: #000 !important;
    }
@media (max-width:769px) {
  .navbar-container {
    max-width: 100%;
    backdrop-filter: blur(0px);
    top: 17.2px;
    left: 0;
    right: 0;
    z-index: 1000;
    &.scrolled {
      top:0;
    }
    .navbar-left {
      display: flex;
      justify-content: flex-start;
      align-items: center; 
      
      .navbar-brand img {
        margin-top: 0.2rem;
        width: 130px;
      }
    }
    

    .navbar-mid {
      display: flex;
      height: 60px;
      margin-left: auto;
      flex-grow: 0;
      justify-content: space-between;
      border: none;
      padding: 0;
    
      .menu-button {
        background: transparent;
        border: none;
        font-weight: 600;
        margin-left: 10px;
        padding: 13px 13px;
        display: block;
        cursor: pointer;
        font-size: 16px;
        color: #ffffff;
        z-index: 1100;
    &.scrolled {
      color: #000000;
    }
    &:hover {
      color: #000000;
    }
      }
    
      .navbar-list {
        display: grid;
        flex-direction: column;
        position: absolute;
        top: 70%;
        border: 1px solid black;
        left: 0;
        background-color: #fffffffe; // Background color for the entire navbar list
        padding: auto;
        visibility: hidden;
        z-index: 1050;
        opacity: 100%;
        transition: visibility 0s, opacity 0.5s linear;
        width: 100%;
      
        &.mobile-menu-open {
          visibility: visible;
          opacity: 100%;
        }
      
        .nav-item {
          width: 100%; // Ensure full width for each nav item
          border: 1px solid black;
          height: 50px;
    
          .nav-link {
            padding-top: 3px;
            padding-bottom: 2px;
            display: block;
            width: 100%;
            color: #000; // Text color for navbar items
          }
        }
      
        .dropdown-content {
          position: static; 
          box-shadow: none;
          width: 100%; // Ensure full width for dropdown content
          background-color: #ffffff00; // Same background as navbar list
          padding: 0; // Remove additional padding
          font-size: 20px;
          text-align: center;
          z-index: 1060;
        }
      }
    }
    .navbar-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
      .nav-right-button {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
  
        li {
          margin-right: auto;
  
          .Donate-btn-nav {
            font-weight: 700;
            font-size: 16px;
            position: relative;
            background: transparent;
            border: #000000 solid 1px;
            background-color: yellow;
            padding: 12px;
          }
        }
      }
    }
  }
}
.navbar-container:hover .menu-button {
  color: #000 !important;
}

@media (max-width:426px) {
  .navbar-container {
    max-width: 100%;
    backdrop-filter: blur(0px);
    top: 17.2px;
    left: 0;
    right: 0;
    z-index: 1000;
    &.scrolled {
      top:0;
    }
    .navbar-left {
      display: flex;
      justify-content: flex-start;
      align-items: center; 
      
      .navbar-brand img {
        margin-top: 0.2rem;
        width: 130px;
      }
    }
    

    .navbar-mid {
      display: flex;
      height: 60px;
      margin-left: auto;
      flex-grow: 0;
      justify-content: space-between;
      border: none;
      padding: 0;
    
      .menu-button {
        background: transparent;
        border: none;
        font-weight: 600;
        margin-left: 10px;
        padding: 13px 13px;
        display: block;
        cursor: pointer;
        font-size: 16px;
        color: #ffffff;
        z-index: 1100;
    &.scrolled {
      color: #000000;
    }
    &:hover {
      color: #000000;
    }
      }
    
      .navbar-list {
        display: grid;
        flex-direction: column;
        position: absolute;
        top: 70%;
        border: 1px solid black;
        left: 0;
        background-color: #fffffffe; // Background color for the entire navbar list
        padding: auto;
        visibility: hidden;
        z-index: 1050;
        opacity: 100%;
        transition: visibility 0s, opacity 0.5s linear;
        width: 100%;
      
        &.mobile-menu-open {
          visibility: visible;
          opacity: 100%;
        }
      
        .nav-item {
          width: 100%; // Ensure full width for each nav item
          border: 1px solid black;
          height: 50px;
    
          .nav-link {
            padding-top: 3px;
            padding-bottom: 2px;
            display: block;
            width: 100%;
            color: #000; // Text color for navbar items
          }
        }
      
        .dropdown-content {
          position: static; 
          box-shadow: none;
          width: 100%; // Ensure full width for dropdown content
          background-color: #ffffff00; // Same background as navbar list
          padding: 0; // Remove additional padding
          font-size: 20px;
          text-align: center;
          z-index: 1060;
        }
      }
    }
    .navbar-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
      .nav-right-button {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
  
        li {
          margin-right: -10px;
  
          .Donate-btn-nav {
            font-weight: 700;
            font-size: 16px;
            position: relative;
            background: transparent;
            border: #000000 solid 1px;
            background-color: yellow;
            padding: 12px;
          }
        }
      }
    }
  }
}
.navbar-container:hover .menu-button {
  color: #000 !important;
}

@media (max-width:376px) {
  .navbar-container {
    max-width: 100%;
    backdrop-filter: blur(0px);
    top: 17.2px;
    left: 0;
    right: 0;
    z-index: 1000;
    &.scrolled {
      top:0;
    }
    .navbar-left {
      display: flex;
      justify-content: flex-start;
      align-items: center; 
      
      .navbar-brand img {
        margin-top: 0.2rem;
        width: 130px;
      }
    }
    

    .navbar-mid {
      display: flex;
      height: 60px;
      margin-left: auto;
      flex-grow: 0;
      justify-content: space-between;
      border: none;
      padding: 0;
    
      .menu-button {
        background: transparent;
        border: none;
        font-weight: 600;
        margin-left: 10px;
        padding: 13px 13px;
        display: block;
        z-index: 1100;
        cursor: pointer;
        font-size: 16px;
        color: #ffffff;
    &.scrolled {
      color: #000000;
    }
    &:hover {
      color: #000000;
    }
      }
    
      .navbar-list {
        display: grid;
        flex-direction: column;
        position: absolute;
        top: 70%;
        border: 1px solid black;
        left: 0;
        background-color: #fffffffe; // Background color for the entire navbar list
        padding: auto;
        visibility: hidden;
        z-index: 1050;
        opacity: 100%;
        transition: visibility 0s, opacity 0.5s linear;
        width: 100%;
      
        &.mobile-menu-open {
          visibility: visible;
          opacity: 100%;
        }
      
        .nav-item {
          width: 100%; // Ensure full width for each nav item
          border: 1px solid black;
          height: 50px;
    
          .nav-link {
            padding-top: 3px;
            padding-bottom: 2px;
            display: block;
            width: 100%;
            color: #000; // Text color for navbar items
          }
        }
      
        .dropdown-content {
          position: static; 
          box-shadow: none;
          width: 100%; // Ensure full width for dropdown content
          background-color: #ffffff00; // Same background as navbar list
          padding: 0; // Remove additional padding
          font-size: 20px;
          text-align: center;
          z-index: 1060;
        }
      }
    }
    .navbar-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
      .nav-right-button {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
  
        li {
          margin-right: -10px;
  
          .Donate-btn-nav {
            font-weight: 700;
            font-size: 16px;
            position: relative;
            background: transparent;
            border: #000000 solid 1px;
            background-color: yellow;
            padding: 12px;
          }
        }
      }
    }
  }
}
.navbar-container:hover .menu-button {
  color: #000 !important;
}
@media (max-width:321px) {
  .navbar-container {
    max-width: 100%;
    height: 42px;
    backdrop-filter: blur(0px);
    top: 17.2px;
    left: 0;
    right: 0;
    z-index: 1000;
    &.scrolled {
      top:0;
    }
    .navbar-left {
      display: flex;
      justify-content: flex-start;
      align-items: center; 
      
      .navbar-brand img {
        margin-top: 0.05rem;
        width: 120px;
      }
    }
    

    .navbar-mid {
      margin-left: auto;
      border: none;
      padding: 0;
    
      .menu-button {
        background: transparent;
        border: none;
        z-index: 1100;
        padding: 13px 13px;
        display: block;
        cursor: pointer;
        font-size: 0.9rem;
        color: #ffffff;
    &.scrolled {
      color: #000000;
    }
    &:hover {
      color: #000000;
    }
      }
    
      .navbar-list {
        top: 60%;
        z-index: 1050;
        &.mobile-menu-open {
          visibility: visible;
          opacity: 100%;
        }
      
        .nav-item {
          width: 100%; // Ensure full width for each nav item
          border: 1px solid black;
          height: 50px;
    
          .nav-link {
            padding-top: 3px;
            padding-bottom: 2px;
            display: block;
            width: 100%;
            color: #000; // Text color for navbar items
          }
        }
      
        .dropdown-content {
          position: static; 
          box-shadow: none;
          width: 100%; // Ensure full width for dropdown content
          background-color: #ffffff00; // Same background as navbar list
          padding: 0; // Remove additional padding
          font-size: 20px;
          text-align: center;
          z-index: 1060;
        }
      }
    }
    .navbar-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
      .nav-right-button {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
  
        li {
          margin-right: -10px;
  
          .Donate-btn-nav {
            font-weight: 700;
            font-size: 0.9rem;
            position: relative;
            background: transparent;
            border: #000000 solid 1px;
            background-color: yellow;
            padding: 12px;
          }
        }
      }
    }
  }
}
.navbar-container:hover .menu-button {
  color: #000 !important;
}
