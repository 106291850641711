.open-positions-page {
  margin-bottom: 50px;
}
.open-positions-page .open-positions-header {
  background-color: black;
  padding: 12% 5% 2%;
  font-weight: 700;
  color: white;
  font-size: 90px;
}
.open-positions-page .opp-elements {
  max-width: 100%;
  margin-left: 50px;
  margin-right: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 50px;
}
.open-positions-page .opp-elements .opp-img {
  max-width: 420px;
  box-shadow: -5px -5px 10px 5px rgba(44, 44, 44, 0.5);
}

@media (max-width: 1024px) {
  .open-positions-page .open-positions-header {
    font-size: 4rem;
  }
  .open-positions-page .opp-elements {
    margin-left: 10px;
    margin-right: 10px;
    grid-template-columns: repeat(1, 1fr); /* Single column layout for small screens */
  }
  .open-positions-page .opp-elements .opp-img {
    max-width: 70%; /* Image takes full width */
    margin: 0 auto; /* Center align image */
    box-shadow: -3px -3px 8px 3px rgba(44, 44, 44, 0.3); /* Slightly lighter shadow */
  }
}
@media (max-width: 768px) {
  .open-positions-page .open-positions-header {
    background-color: black;
    padding-top: 95px;
    padding-left: 20px;
    font-weight: 700;
    font-size: 45px;
    text-align: center;
    padding-bottom: 45px;
  }
  .open-positions-page .opp-elements {
    margin-left: 10px;
    margin-right: 10px;
    grid-template-columns: repeat(1, 1fr); /* Single column layout for small screens */
  }
  .open-positions-page .opp-elements .opp-img {
    max-width: 70%; /* Image takes full width */
    margin: 0 auto; /* Center align image */
    box-shadow: -3px -3px 8px 3px rgba(44, 44, 44, 0.3); /* Slightly lighter shadow */
  }
}
@media (max-width: 426px) {
  .open-positions-page .open-positions-header {
    background-color: black;
    padding-top: 95px;
    padding-left: 20px;
    font-weight: 700;
    font-size: 45px;
    text-align: center;
    padding-bottom: 45px;
  }
  .open-positions-page .opp-elements {
    margin-left: 10px;
    margin-right: 10px;
    grid-template-columns: repeat(1, 1fr); /* Single column layout for small screens */
  }
  .open-positions-page .opp-elements .opp-img {
    max-width: 80%; /* Image takes full width */
    margin: 0 auto; /* Center align image */
    box-shadow: -3px -3px 8px 3px rgba(44, 44, 44, 0.3); /* Slightly lighter shadow */
  }
}
@media (max-width: 376px) {
  .open-positions-page .open-positions-header {
    background-color: black;
    padding-top: 95px;
    padding-left: 20px;
    font-weight: 700;
    font-size: 45px;
    text-align: center;
    padding-bottom: 45px;
  }
  .open-positions-page .opp-elements {
    margin-left: 10px;
    margin-right: 10px;
    grid-template-columns: repeat(1, 1fr); /* Single column layout for small screens */
  }
  .open-positions-page .opp-elements .opp-img {
    max-width: 90%; /* Image takes full width */
    margin: 0 auto; /* Center align image */
    box-shadow: -3px -3px 8px 3px rgba(44, 44, 44, 0.3); /* Slightly lighter shadow */
  }
}
@media (max-width: 320px) {
  .open-positions-page .open-positions-header {
    background-color: black;
    padding-top: 95px;
    padding-left: 20px;
    font-weight: 700;
    font-size: 45px;
    text-align: center;
    padding-bottom: 45px;
  }
  .open-positions-page .opp-elements {
    margin-left: 10px;
    margin-right: 10px;
    grid-template-columns: repeat(1, 1fr); /* Single column layout for small screens */
  }
  .open-positions-page .opp-elements .opp-img {
    max-width: 90%; /* Image takes full width */
    margin: 0 auto; /* Center align image */
    box-shadow: -3px -3px 8px 3px rgba(44, 44, 44, 0.3); /* Slightly lighter shadow */
  }
}/*# sourceMappingURL=open-positions.css.map */