    .header-content {
      display: flex;
      align-items: center;
      padding: 1px 10px;
    
      .dte-code {
        font-size: 0.8rem;
        color: #000000;
        font-weight: 700;
        &:hover {
          color: blue;
        }
      }
      .darpan{
      font-size: 0.8rem;
      color: #000000;
      font-weight: 700;
      margin-left: 80px;
      &:hover {
        color: blue;
      }}

      .social-media {
        display: flex;
        gap: 5px;
        font-size: 0.8rem;
        font-weight: 700;
        margin-left: 4rem;
        a {
          margin: 0 2px;
          text-decoration: none;
          color: #ff6200;
          
        }
      }
    
      .erp-info {
        display: flex;
        margin-left: auto;
        
        a {
          font-size: 0.8rem;
          color: #000;
          font-weight: 700;
          text-decoration: none;
          &:hover {
            color: blue;
          }
        }
      }
    }
    @media (max-width:1440px) {
          .header-content {
            display: flex;
            align-items: center;
            padding: 1px 10px;
          
            .dte-code {
              font-size: 0.8rem;
              color: #000000;
              font-weight: 700;
              &:hover {
                color: blue;
              }
            }
           
          
            .social-media {
              display: flex;
              gap: 5px;
              font-size: 0.8rem;
              font-weight: 700;
              margin-left: 2rem;
              a {
                margin: 0 2px;
                text-decoration: none;
                color: #ff6200;
                
              }
            }
          
            .erp-info {
              display: flex;
              margin-left: auto;
              
              a {
                font-size: 0.8rem;
                color: #000;
                font-weight: 700;
                text-decoration: none;
                &:hover {
                  color: blue;
                }
              }
            }
          }
          }
      @media (max-width:1024px) {
          .header-content {
            display: flex;
            align-items: center;
            padding: 1px 10px;
          
            .dte-code {
              font-size: 0.8rem;
              color: #000000;
              font-weight: 700;
              &:hover {
                color: blue;
              }
            }
        
            .social-media {
              display: flex;
              gap: 5px;
              font-size: 0.8rem;
              font-weight: 700;
              margin-left: 2rem;
              a {
                margin: 0 2px;
                text-decoration: none;
                color: #ff6200;
                
              }
            }
          
            .erp-info {
              display: flex;
              margin-left: auto;
              
              a {
                font-size: 0.8rem;
                color: #000;
                font-weight: 700;
                text-decoration: none;
                &:hover {
                  color: blue;
                }
              }
            }
          }
          }
          @media (min-width:769px) and (max-width:886px) {
                .header-content {
                    display: flex;
                    font-size: 1.2rem;
                    position: sticky; 
                    
                .dte-code {
                    font-weight: 700;
                    
                }
                
                .social-media {
                  display: flex;
                  gap: 5px;
                  font-size: 0.8rem;
                  font-weight: 700;
                  margin-left: auto; /* Align to the right by pushing it to the right margin */
                  
                  a {
                    margin: 0 2px;
                    text-decoration: none;
                    color: #ff6200; 
                  }
                }
                
              
                .erp-info {
                    display: none;
                    
              }
            }
          }
      @media (max-width:769px) {
            .header-content {
                display: flex;
                font-size: 1.2rem;
                position: sticky; 
                
            .dte-code {
                font-weight: 700;
                
            }
            .darpan{
              display: none;}
          
            .social-media {
              display: flex;
              gap: 5px;
              font-size: 0.8rem;
              font-weight: 700;
              margin-left: auto; /* Align to the right by pushing it to the right margin */
              
              a {
                margin: 0 2px;
                text-decoration: none;
                color: #ff6200; 
              }
            }
            
          
            .erp-info {
                display: none;
                
          }
        }
      }
      @media (max-width:426px) {
            .header-content {
                display: flex;
                font-size: 1.2rem;
                position: sticky; 
                
            .dte-code {
                font-weight: 700;
                
            }
            .darpan{
              display: none;}
            .social-media {
              display: flex;
              gap: 5px;
              font-size: 0.8rem;
              font-weight: 700;
              margin-left: auto; /* Align to the right by pushing it to the right margin */
              
              a {
                margin: 0 2px;
                text-decoration: none;
                color: #ff6200; 
              }
            }
            
          
            .erp-info {
                display: none;
                
          }
        }
      }
      @media (max-width:376px) {
            .header-content {
                display: flex;
                font-size: 1.2rem;
                position: sticky; 
                
            .dte-code {
                font-weight: 700;
                
            }
            .darpan{
              display: none;}
            .social-media {
              display: flex;
              gap: 5px;
              font-size: 0.8rem;
              font-weight: 700;
              margin-left: auto; /* Align to the right by pushing it to the right margin */
              
              a {
                margin: 0 2px;
                text-decoration: none;
                color: #ff6200; 
              }
            }
            
          
            .erp-info {
                display: none;
                
          }
        }
      }
      @media (max-width:321px) {
            .header-content {
                display: flex;
                font-size: 0.8rem;
                position: sticky; 
                
            .dte-code {
                font-weight: 700;
                
            }
            .darpan{
              display: none;}
            .social-media {
              display: none;
              
            }
            
          
            .erp-info {
                display: none;
                
          }
        }}